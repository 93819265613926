<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="time"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        class="m-0 p-0"
        style="width: 120px;"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="modal"
      v-model="time"
      scrollable
      format="24hr"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="save(time)">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: '',

  components: {

  },

  props: {
    label: {
      type: String,
      required: false,
    },
    val: {
      type: String,
      required: false,
    }
  },

  data: () => ({
    modal: null,
    time: null,
  }),

  watch: {
    val() {
      this.time = this.val;
    }
  },

  methods: {
    save: function(time) {
      this.$refs.dialog.save(time);
      this.$emit('time', time);
    }
  },

  created () {
    this.time = this.val;
  },

  mounted () {

  },
};
</script>

<style>
  .v-dialog {
    max-width: 400px !important;
  }
</style>
