<template>
  <div
    class="ma-auto"
    style="position: relative; width: 100%; max-width: 400px;"
  >
    <bar-graph
      :key="key"
      :elems="elems"
      :data="data"
      :red="red"
      :green="green"
      :blue="blue"
      :lo="lo"
      :hi="hi"
      :speed="speed"
      :sorted="sorted"
      :steps="steps"
      @restart="restart($event)"
      @stop="stop()"
      @speed="updateSpeed($event)"
    />
  </div>
</template>

<script>
/* IMPORTS */
import BarGraph from '@/components/BarGraph.vue';

export default {
  name: 'Sorting',

  components: {
    BarGraph
  },

  props: {
    type: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    elems: [],
    data: {
      state: 'stop',
      max: 40,
      min: 2,
      n: 50,
    },
    red: -1,
    green: -1,
    blue: -1,
    lo: -1,
    hi: -1,
    key: 0,
    speed: 75,
    calls: 0,
    steps: 0,
    lastSteps: 0,
    lastStepsCount: 0,
    sorted: false,
    sortCheckInterval: null,
  }),

  watch: {

  },

  computed: {

  },

  methods: {
    stop() {
      this.data.state = 'stop';
      clearInterval(this.sortCheckInterval);
    },

    restart(itemSet) {
      console.log('restart')
      this.data.state = 'stop';
      setTimeout(() => {
        this.updateColors(-1, -1, -1);
        this.updateRange(-1, -1);
        this.calls = 0;
        this.steps = 0;
        this.lastSteps = 0;
        this.lastStepsCount = 0;
        this.resetSortCheck();
        if (itemSet === 'sorted')
          this.populateSorted();
        else if (itemSet === 'unsorted')
          this.populateUnsorted();
        else
          this.populateRandom();
        this.startSort();
      }, this.data.speed * 2);
    },

    async startSortData() {
      this.sorted = false;
    },

    isThisSorted() {
      console.log('calls', this.calls)
      if (this.calls === 0) {
        this.sorted = true;
        clearInterval(this.sortCheckInterval);
      }
      else {
        this.sorted = false;
      }
    },

    resetSortCheck() {
      clearInterval(this.sortCheckInterval);
      this.sortCheckInterval = setInterval(() => {
        this.isThisSorted();
      }, this.speed);
    },

    updateSpeed(e) {
      if (e<0)
        this.speed = 0;
      else
        this.speed = e;
      this.resetSortCheck();
    },

    increaseSteps() {
      this.steps++;
    },

    decreaseSteps() {
      this.steps--;
    },

    populateRandom() {
      this.elems = [];
      for(let i = 0; i < this.data.n; i++)
        this.elems.push(Math.round(Math.random() * Math.abs(this.data.max - this.data.min) + Math.min(this.data.min, this.data.max)));
    },

    populateSorted() {
      this.elems = [];
      this.data.max = this.data.n + 1;
      for(let i = 0; i < this.data.n; i++)
        this.elems.push(i + 1);
    },

    populateUnsorted() {
      this.elems = [];
      this.data.max = this.data.n + 1;
      for(let i = 0; i < this.data.n; i++)
        this.elems.push(this.data.max - i);
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    isSorted(A) {
      for (let i = 0; i < A.length - 1; i++)
        if (A[i] > A[i + 1])
          return false;
      return true;
    },

    updateColors(red=this.red, green=this.green, blue=this.blue, ) {
      this.red = red;
      this.green = green;
      this.blue = blue;
    },

    updateRange(lo=this.lo, hi=this.hi) {
      this.lo = lo;
      this.hi = hi;
    },

    increaseCalls() {
      this.calls++;
    },

    decreaseCalls() {
      this.calls--;
    },

    swap(A, i, j) {
      const tmp = A[i];
      A[i] = A[j];
      A[j] = tmp;
    },

    async quicksort(A, lo, hi) {
      if (this.data.state === 'stop') return -1;
      this.increaseCalls();
      if (lo < hi) {
        if (this.data.state === 'stop') return -1;
        let p = await this.partition(A, lo, hi);
        if (this.data.state === 'stop') return -1;
        await this.quicksort(A, lo, p - 1);
        if (this.data.state === 'stop') return -1;
        await this.quicksort(A, p + 1, hi);
        if (this.data.state === 'stop') return -1;
      }
      this.decreaseCalls();
    },

    async partition(A, lo, hi) {
      if (this.data.state === 'stop') return -1;
      let pivot = A[hi];
      this.updateRange(lo, hi);
      this.updateColors(hi, undefined, undefined);
      let i = lo;
      for (let j = lo; j < hi; j++) {
        if (A[j] < pivot) {
          this.swap(A, i, j);
          i = i + 1;
        }
        this.increaseSteps();
        this.updateColors(hi, i, j);
        if (this.data.state === 'stop') return -1;
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;
      }
      if (this.data.state === 'stop') return -1;
      this.updateColors(hi, i, hi);
      this.swap(A, i, hi);
      if (this.data.state === 'stop') return -1;
      await this.sleep(this.data.speed);
      if (this.data.state === 'stop') return -1;
      return i;
    },

    async mergesort(A, lo, hi) {
      if (this.data.state === 'stop') return -1;
      this.increaseCalls();
      if (lo < hi) {
        const m = Math.floor((lo + hi) / 2);
        if (this.data.state === 'stop') return -1;
        await this.mergesort(A, lo, m);
        if (this.data.state === 'stop') return -1;
        await this.mergesort(A, m + 1, hi);
        if (this.data.state === 'stop') return -1;
        await this.merge(A, lo, m, hi);
        if (this.data.state === 'stop') return -1;
      }
      this.decreaseCalls();
    },

    async merge(A, lo, m, hi) {
      if (this.data.state === 'stop') return -1;
      let n1 = m - lo + 1;
      let n2 = hi - m;

      const L = [];
      const R = [];

      for (let i = 0; i < n1; i++)
        L.push(A[lo + i]);
      for (let i = 0; i < n2; i++)
        R.push(A[m + 1 + i]);

      let i = 0;
      let j = 0;
      let k = lo;

      if (this.data.state === 'stop') return -1;
      this.updateRange(lo, hi + 1);
      this.updateColors(k , i + lo, j + m + 1);
      await this.sleep(this.speed);
      if (this.data.state === 'stop') return -1;

      while (i < n1 && j < n2) {
        if (L[i] < R[j]) {
          A[k] = L[i];
          i++;
        } else {
          A[k] = R[j];
          j++;
        }
        k++;

        if (this.data.state === 'stop') return -1;
        this.increaseSteps();
        this.updateColors(k, i + lo, j + m + 1);
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;
      }

      while (i < n1) {
        A[k] = L[i];
        i++; k++;

        if (this.data.state === 'stop') return -1;
        this.increaseSteps();
        this.updateColors(k, i + lo, undefined);
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;
      }

      while (j < n2) {
        A[k] = R[j];
        j++; k++;

        if (this.data.state === 'stop') return -1;
        this.increaseSteps();
        this.updateColors(k, undefined, j + m + 1);
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;
      }
    },

    async insertionSort(A) {
      if (this.data.state === 'stop') return -1;
      let key;
      let j;
      this.calls = A.length - 1;
      for (let i = 1; i < A.length; i++) {
        if (this.data.state === 'stop') return -1;
        key = A[i];
        j = i - 1;

        while (j >= 0 && A[j] > key) {
          A[j + 1] = A[j];
          j--;

          if (this.data.state === 'stop') return -1;
          this.increaseSteps();
          this.updateRange(j, i);
          this.updateColors(i, j, key);
          await this.sleep(this.speed);
          if (this.data.state === 'stop') return -1;
        }
        A[j + 1] = key;

        if (this.data.state === 'stop') return -1;
        this.increaseSteps();
        this.updateColors(i, j, key);
        await this.sleep(this.speed);
        this.decreaseCalls();
        if (this.data.state === 'stop') return -1;
      }
    },

    async heapSort(A) {
      if (this.data.state === 'stop') return -1;
      for (let i = A.length / 2 - 1; i >= 0; i--) {
        this.updateRange(0, i);

        if (this.data.state === 'stop') return -1;
        await this.heapify(A, A.length, i);
        if (this.data.state === 'stop') return -1;
      }

      for (let i = A.length - 1; i > 0; i--) {
        this.updateRange(0, i);
        this.swap(A, 0, i);

        if (this.data.state === 'stop') return -1;
        await this.heapify(A, i, 0);
        if (this.data.state === 'stop') return -1;
      }
    },

    async heapify(A, n, i) {
      if (this.data.state === 'stop') return -1;
      this.increaseCalls();
      let largest = i;
      let l = 2 * i + 1;
      let r = 2 * i + 2;

      if (l < n && A[l] > A[largest]) {
        largest = l;
      }
      if (r < n && A[r] > A[largest]) {
        largest = r;
      }

      if (this.data.state === 'stop') return -1;
      this.increaseSteps();
      this.increaseSteps();
      this.updateColors(largest, i, undefined);
      await this.sleep(this.speed);
      if (this.data.state === 'stop') return -1;

      if (largest !== i) {
        this.swap(A, i, largest);

        if (this.data.state === 'stop') return -1;
        this.updateColors(largest, i, undefined);
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;

        await this.heapify(A, n, largest);
      }
      this.decreaseCalls();
    },

    async bubbleSort(A) {
      if (this.data.state === 'stop') return -1;
      this.calls = A.length - 1;
      for (let i = 0; i < A.length - 1; i++) {
        if (this.data.state === 'stop') return -1;
        for (let j = 0; j < A.length - i - 1; j++) {
          if (this.data.state === 'stop') return -1;

          if (A[j] > A[j + 1]) {
            this.swap(A, j, j + 1);
          }

          if (this.data.state === 'stop') return -1;
          this.increaseSteps();
          this.updateRange(i, j);
          this.updateColors(i, j + 1, undefined);
          await this.sleep(this.speed);
          if (this.data.state === 'stop') return -1;
        }
        this.decreaseCalls();
      }
    },

    async selectionSort(A) {
      if (this.data.state === 'stop') return -1;
      this.calls = A.length * (A.length - 1) / 2;
      for (let i = 0; i < A.length; i++) {
        let min_i = i;
        for (let j = i + 1; j < A.length; j++) {
          if (A[min_i] > A[j]) {
            min_i = j;
          }
          if (this.data.state === 'stop') return -1;
          this.increaseSteps();
          this.updateRange(i, j);
          this.updateColors(i, min_i, undefined);
          await this.sleep(this.speed);
          this.decreaseCalls();
          if (this.data.state === 'stop') return -1;
        }

        this.swap(A, i, min_i);
        if (this.data.state === 'stop') return -1;
        this.updateColors(i, min_i, undefined);
        await this.sleep(this.speed);
        if (this.data.state === 'stop') return -1;
      }
    },

    async stoogeSort(A, l, h) {
      if (this.data.state === 'stop') return -1;
      this.increaseCalls();

      if (l >= h) {
        return;
      }
      
      if (A[l] > A[h]) {
        this.swap(A, l, h);
      }
      this.increaseSteps();

      if (this.data.state === 'stop') return -1;
      this.updateColors(l, h);
      await this.sleep(this.speed);
      if (this.data.state === 'stop') return -1;

      if (h - l + 1 > 2) {
        let t = Math.floor((h - l + 1) / 3);
        await this.stoogeSort(A, l, h - t);
        await this.stoogeSort(A, l + t, h);
        await this.stoogeSort(A, l, h - t);
      }
      this.decreaseCalls();
    },

    startSort() {
      setTimeout(() => {
        this.resetSortCheck();
        this.data.state = 'play';
        if (this.type === 'quicksort')
          this.quicksort(this.elems, 0, this.elems.length - 1);
        else if (this.type === 'mergesort')
          this.mergesort(this.elems, 0, this.elems.length - 1);
        else if (this.type === 'insertion-sort')
          this.insertionSort(this.elems);
        else if (this.type === 'heap-sort')
          this.heapSort(this.elems);
        else if (this.type === 'bubble-sort')
          this.bubbleSort(this.elems);
        else if (this.type === 'selection-sort')
          this.selectionSort(this.elems);
        else if (this.type === 'stooge-sort')
          this.stoogeSort(this.elems, 0, this.elems.length - 1);
        else
          console.error("Sorting algorithm '" + this.type + "' not found.");
      }, this.speed);
    }
  },

  created () {
    this.$emit('toolbar', true);
    this.stop();
  },

  mounted () {
    console.log(this.type);
    this.restart()
  },

  beforeDestroy () {
    this.stop();
  },
}
</script>

<style scoped>

</style>
