<template>
  <form
    class="p-5 pt-0"
    style="width: 100%;"
    v-on:submit.prevent="send()"
  >
    <form-title :loading="loading" :title="title" :loadingTitle="loadingTitle" />
    <v-text-field
      v-model="email"
      label="Email"
      :disabled="loading"
    />

    <div
      v-if="!loading"
      class="d-flex flex-column flex-column-reverse align-start"
    >
      <div
        class="d-flex justify-end align-center mt-7"
        style="width: 100%;"
      >
        <v-btn
          type=submit
          color="primary"
          style="width: 100px;"
        >
          Send
        </v-btn>
      
      </div>

      <div class="mb-3" style="font-size: 13px; color: red;">{{ errorMessage }}</div>
    </div>
  </form>
</template>

<script>
import FormTitle from '@/components/FormTitle.vue';

export default {
  name: 'RecoverForm',

  components: {
    FormTitle,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    loadingTitle: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    email: null,
    password: null,
    flat: false,
    loading: false,
    errorMessage: null,
  }),

  methods: {
    send() {
      console.log('ok');
      this.loading = true;
      setTimeout(()=>{this.loading = false;}, 4000);
    }
  },
};
</script>