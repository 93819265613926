module.exports =
[
  {
    title: 'Quicksort',
    description: '',
    link: '/resources/sorting/quicksort'
  },
  {
    title: 'Mergesort',
    description: '',
    link: '/resources/sorting/mergesort'
  },
  {
    title: 'Insertion Sort',
    description: '',
    link: '/resources/sorting/insertion-sort'
  },
  {
    title: 'Heapsort',
    description: '',
    link: '/resources/sorting/heapsort'
  },
  {
    title: 'Bubble Sort',
    description: '',
    link: '/resources/sorting/bubble-sort'
  },
  {
    title: 'Selection Sort',
    description: '',
    link: '/resources/sorting/selection-sort'
  },
  {
    title: 'Stooge Sort',
    description: '',
    link: '/resources/sorting/stooge-sort'
  },
]