module.exports =
[
  {
    type: 'Text',
    heading: 'Summary',
    body: ''
  },
  {
    type: 'Text',
    heading: 'Time Complexity:',
    body: ''
  },
  {
    type: 'Component',
    component: 'Sorting',
    sortType: 'heap-sort',
  },
  {
    type: 'Code',
    heading: 'Implementation',
    body: ""
  },
  {
    type: 'Text',
    heading: 'Conclusion',
    body: ''
  }
]