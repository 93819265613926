module.exports =
[
  {
    type: 'Text',
    heading: 'Summary',
    body: 'Mergesort is an efficient, general-purpose, comparison-based sorting algorithm. Most implementations produce a stable sort, which means that the order of equal elements is the same in the input and output. Merge sort is a divide and conquer algorithm that was invented by John von Neumann in 1945.'
  },
  {
    type: 'Text',
    heading: 'Time Constraint: Θ(n logn)',
    body: 'Mathematical analysis of mergesort shows that, on average, the algorithm takes Θ(n log n) comparisons to sort n items.'
  },
  {
    type: 'Text',
    heading: 'Space Constraint: O(n)',
    body: 'Mergesort stores each half of the merging sections in separate arrays, therefore making the minimum space complexity equal to 2, on start of each merge section, and maximum equal to n on the last merge.'
  },
  {
    type: 'Component',
    component: 'Sorting',
    sortType: 'mergesort',
  },
  {
    type: 'Code',
    heading: 'Implementation',
    body: "mergesort(A, lo, hi) {\n" +
      "\tif (lo < hi) {\n" +
      "\t\tconst m = Math.floor((lo + hi) / 2);\n" +
      "\t\tthis.mergesort(A, lo, m);\n" +
      "\t\tthis.mergesort(A, m + 1, hi);\n" +
      "\t\tthis.merge(A, lo, m, hi);\n" +
      "\t}\n" +
      "},\n" +
      "\n" +
      "merge(A, lo, m, hi) {\n" +
      "\tlet n1 = m - lo + 1;\n" +
      "\tlet n2 = hi - m;\n" +
      "\n" +
      "\tconst L = [];\n" +
      "\tconst R = [];\n" +
      "\n" +
      "\tfor (let i = 0; i < n1; i++)\n" +
      "\t\tL.push(A[lo + i]);\n" +
      "\tfor (let i = 0; i < n2; i++)\n" +
      "\t\tR.push(A[m + 1 + i]);\n" +
      "\n" +
      "\tlet i = 0;\n" +
      "\tlet j = 0;\n" +
      "\tlet k = lo;\n" +
      "\n" +
      "\twhile (i < n1 && j < n2) {\n" +
      "\t\tif (L[i] < R[j]) {\n" +
      "\t\t\tA[k] = L[i];\n" +
      "\t\t\ti++;\n" +
      "\t\t}\n" +
      "\t\telse {\n" +
      "\t\t\tA[k] = R[j];\n" +
      "\t\t\tj++;\n" +
      "\t\t}\n" +
      "\t\tk++;\n" +
      "\t}\n" +
      "\n" +
      "\twhile (i < n1) {\n" +
      "\t\tA[k] = L[i];\n" +
      "\t\ti++; k++;\n" +
      "\t}\n" +
      "\n" +
      "\twhile (j < n2) {\n" +
      "\t\tA[k] = R[j];\n" +
      "\t\tj++; k++;\n" +
      "\t}\n" +
      "}"
  },
  {
    type: 'Text',
    heading: 'Conclusion',
    body: 'Mergesort is one of the best sorting algorithms because of the low time complexity with all types of datasets. The only downside would be the space complexity with very large datasets, unless expanding memory is not an issue.'
  }
]