





















































































































import Vue from 'vue';
import Logo from '@/components/Logo.vue'
import Brand from '@/components/Brand.vue'

export default Vue.extend({
  name: 'App',

  components: {
    Logo,
    Brand,
  },

  data: () => ({
    env: 'dev',
    success: '',
    error: '',
    documentWidth: 0,
    toolbarHeight: 0,
    view: null,
    footer: true,
    toolbar: true,
    loggedIn: false,
    currentYear: 0,
    drawer: false,
    avatar: {
      img: null,
      abbr: 'BG',
      color: 'primary',
    },
    menuItems: [
      {
        title: 'Home',
        icon: 'mdi-home',
        link: '/',
      },
      {
        title: 'Products & Services',
        icon: 'mdi-web',
        link: '/need-a-website',
      },
      {
        title: 'Contact',
        icon: 'mdi-email',
        link: '/contact',
      },
      {
        title: 'Tools',
        icon: 'mdi-cog',
        link: '/tools',
      },
      {
        title: 'Resources',
        icon: 'mdi-book',
        link: '/resources',
      },
    ]
  }),

  methods: {
    logout: function() {

    },
    
    createElement(tag: any, attrs: any) {
      let e = document.createElement(tag);
      Object.keys(attrs).forEach((key: any) => {
        e.setAttribute(key, attrs[key])
      });
      document.head.appendChild(e);
    },

    onresize() {
      this.documentWidth = document.body.clientWidth;
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.onresize);
  },

  created() {
    window.addEventListener('resize', this.onresize);

    this.createElement('link', {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favico/apple-touch-icon.png'
    })
    this.createElement('link', {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favico/favicon-32x32.png'
    })
    this.createElement('link', {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favico/favicon-16x16.png'
    })
    this.createElement('link', {
      rel: 'manifest',
      href: '/favico/site.webmanifest'
    })
    this.createElement('link', {
      rel: 'mask-icon',
      href: 'favico/safari-pinned-tab.svg',
      color: '#5bbad5'
    })
    this.createElement('link', {
      rel: 'shortcut icon',
      href: '/favico/favicon.ico'
    })

    this.createElement('meta', {
      name: 'msapplication-TileColor',
      content: '#00aba9'
    })
    this.createElement('meta', {
      name: 'msapplication-config',
      content: '/favico/browserconfig.xml'
    })
    this.createElement('meta', {
      name: 'theme-color',
      content: '#ffffff'
    })
    this.currentYear = (new Date()).getFullYear();

    const hostname = window.location.hostname;
    if (hostname === 'localhost' || hostname === '127.0.0.1')
      this.env = 'dev';
    else
      this.env = 'prod';
  },

  mounted() {
    this.documentWidth = document.body.clientWidth;
  }
});
