<template>
  <div
    class="d-flex"
  >
    <time-picker
      v-model="t1"
      label="Start Time"
      v-on:time="emitT1($event)"
      :val="start"
    />
    <v-spacer />
    <time-picker
      v-model="t2"
      label="End Time"
      v-on:time="emitT2($event)"
      :val="end"
    />
    <v-spacer />
    <v-btn 
      fab
      class="mt-1"
      color="error"
      x-small
      v-on:click="$emit('remove', id)"
    >
    <v-icon color="white" small>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import TimePicker from '@/components/tools/time-calculator/TimePicker.vue';

export default {
  name: 'TwoTimes',

  components: {
    TimePicker,
  },

  props: {
    id: {
      type: Number,
      required: false,
    },
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    }
  },

  data: () => ({
    t1: null,
    t2: null,
    diff: 0,
  }),

  watch: {
    start() {
      if (this.start !== null)
        this.t1 = this.start;
    },
    end() {
      if (this.end !== null)
        this.t2 = this.end;
    },
    t1() {
      this.timeDiff();
    },
    t2() {
      this.timeDiff();
    }
  },

  methods: {
    emitT1: function(event) {
      this.t1 = event;
      this.$emit('update', {id: this.id, start: this.t1});
    },
    emitT2: function(event) {
      this.t2 = event;
      this.$emit('update', {id: this.id, end: this.t2});
    },
    timeDiff: function() {
      if (this.t1 !== null && this.t2 !== null) {
        let diff = (this.t2.substr(0,2) * 60) - (this.t1.substr(0,2) * 60);
        diff += this.t2.substr(3,2) - this.t1.substr(3,2);
        this.diff = diff;
        this.$emit('update', {id: this.id, start: this.t1, end: this.t2, diff: this.diff});
      }
    }
  },

  created () {
    if (this.start !== null)
      this.t1 = this.start;
    if (this.end !== null)
      this.t2 = this.end;
  },

  mounted () {

  },
};
</script>

<style scoped>

</style>
