<template>
  <v-card
    max-width="300"
    class="d-flex flex-column justify-space-between p-2 pt-3 mx-5 my-3"
    tile
  >
    <h3
      class="mx-auto text-center pt-3"
    >
      <span v-if="icon" :class="'mr-2 mdi ' + icon"></span>
      <span>{{ title }}</span>
    </h3>
    <v-card-text>{{ text }}</v-card-text>
    <v-card-actions>
      <v-btn
        v-if="link"
        text
        class="ml-auto mr-0"
        :href="link"
        target="_blank"
        color="primary"
        style="font-weight: bold;"
        tile
      >
        Learn More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import ComponentName from '../components/ComponentName.vue'

export default {
  name: 'VCardV1',

  components: {

  },

  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
  },

  data: () => ({

  }),

  watch: {

  },

  methods: {

  },

  created () {

  },

  mounted () {

  },
};
</script>

<style scoped>

</style>
