<template>
  <div class="d-flex align-center justify-center mb-5 pt-10" style="width: 100%;">
    <div v-if="!loading" class="d-flex align-center" style="position: relative; left: -17px;">
      <loader key="loader1" :cycles="0" class="mr-3 my-auto" size="30px" />
      <h1 class="text-center ma-0">{{ title }}</h1>
    </div>
    <div v-else class="d-flex flex-column align-center">
      <h2>{{ loadingTitle }}</h2>
      <loader key="loader2" class="mt-5" size="40px" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  name: 'FormTitle',

  components: {
    Loader,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loadingTitle: {
      type: String,
      required: true,
    }
  }
}
</script>