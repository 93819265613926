<template>
  <div>
    <h1 class="my-5">{{ title }}</h1>
    <div
      v-for="(item, i) in items"
      :key="'item' + i"
      class="ma-auto px-3 mb-10"
      style="max-width: 700px; width: 100%;"
    >

      <div
        v-if="item.type=='Text'"
      >
        <h2 class="text-left mb-2">{{ item.heading }}</h2>
        <p
          style="white-space: pre-wrap;"
        >{{ item.body }}</p>
      </div>

      <div
        v-if="item.type=='Code'"
      >
        <h2 class="text-left mb-2">{{ item.heading }}</h2>
        <v-card
          class="code"
          flat
        >
          <code>{{ item.body }}</code>
        </v-card>
      </div>

      <div
        v-else-if="item.type=='Image'"
        style="width: 100%;"
      >

      </div>

      <div
        v-else-if="item.type=='Component'"
        style="width: 100%;"
      >
        <component
          :key="new Date().getTime()"
          :is="item.component"
          :type="item.sortType"
        /> 
      </div>

    </div>
  </div>
</template>

<script>
/* IMPORTS */
import * as components from '@/components/resources/index.js'; 

export default {
  name: 'Resource',

  components,

  props: {
    title: {
      type: String,
      default: 'Default Title', 
    },
    items: {
      type: Array,
      required: true, 
    },
  },

  data: () => ({

  }),

  watch: {

  },
  computed: {

  },

  methods: {
    loadComponents: function() {
      const comps = [];
      for (const item of this.items)
        if (item.type === 'Component')
          comps.push(item.component);
      this.$options.components.items = comps;
    }
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);

    this.loadComponents();
  },
}
</script>

<style>
  .code, code {
    background: #FAFAFA !important;
    color: #191919 !important;
    /* line-height: 100% !important; */
    font-size: 14px !important;
    white-space: pre-wrap;
  } 
  .code {
    /* border: 10px solid #CCC !important; */
    padding: 10px !important;
  }
  code {
    padding: 0px !important;
  }
</style>
