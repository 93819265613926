<template>
  <div v-if="dark" class="d-flex">
    <svg xmlns="http://www.w3.org/2000/svg" :height="height" fill="none" viewBox="0 0 267 142">
      <path fill="#333" d="M56.438 60c14.666.8 25.8 4.8 33.4 12 7.733 7.067 11.6 16.333 11.6 27.8 0 8-1.934 15.133-5.8 21.4-3.867 6.133-9.6 11-17.2 14.6-7.6 3.6-16.867 5.4-27.8 5.4-10.134 0-19.734-1.6-28.8-4.8-8.934-3.333-16.134-7.733-21.6-13.2l7-11.4c4.666 4.8 10.866 8.733 18.6 11.8 7.733 2.933 16 4.4 24.8 4.4 11.466 0 20.333-2.467 26.6-7.4 6.266-5.067 9.4-12 9.4-20.8 0-8.667-3.134-15.467-9.4-20.4-6.267-4.933-15.734-7.4-28.4-7.4h-10V61.4l38.8-48.6h-71.6V0h90v10.2L56.437 60zm77.69-60h57c14.8 0 27.867 3 39.2 9 11.333 5.867 20.133 14.133 26.4 24.8 6.267 10.533 9.4 22.6 9.4 36.2 0 13.6-3.133 25.733-9.4 36.4-6.267 10.533-15.067 18.8-26.4 24.8-11.333 5.867-24.4 8.8-39.2 8.8h-57V0zm56.2 127.2c12.267 0 23-2.4 32.2-7.2 9.2-4.933 16.333-11.667 21.4-20.2 5.067-8.667 7.6-18.6 7.6-29.8s-2.533-21.067-7.6-29.6c-5.067-8.667-12.2-15.4-21.4-20.2-9.2-4.933-19.933-7.4-32.2-7.4h-41.4v114.4h41.4z"/>
    </svg>

    <loader :size="height + 'px'" :cycles="1" :style="'margin: 0 ' + (height/5) + 'px;'"/>

    <svg xmlns="http://www.w3.org/2000/svg" :height="height" fill="none" viewBox="0 0 115 140">
      <path fill="#333" d="M50.078 12.8H.878V0h113.2v12.8h-49.2V140h-14.8V12.8z"/>
    </svg>
  </div>
  <div v-else class="d-flex">
    <svg xmlns="http://www.w3.org/2000/svg" :height="height" fill="none" viewBox="0 0 267 142">
      <path fill="#fff" d="M56.438 60c14.666.8 25.8 4.8 33.4 12 7.733 7.067 11.6 16.333 11.6 27.8 0 8-1.934 15.133-5.8 21.4-3.867 6.133-9.6 11-17.2 14.6-7.6 3.6-16.867 5.4-27.8 5.4-10.134 0-19.734-1.6-28.8-4.8-8.934-3.333-16.134-7.733-21.6-13.2l7-11.4c4.666 4.8 10.866 8.733 18.6 11.8 7.733 2.933 16 4.4 24.8 4.4 11.466 0 20.333-2.467 26.6-7.4 6.266-5.067 9.4-12 9.4-20.8 0-8.667-3.134-15.467-9.4-20.4-6.267-4.933-15.734-7.4-28.4-7.4h-10V61.4l38.8-48.6h-71.6V0h90v10.2L56.437 60zm77.69-60h57c14.8 0 27.867 3 39.2 9 11.333 5.867 20.133 14.133 26.4 24.8 6.267 10.533 9.4 22.6 9.4 36.2 0 13.6-3.133 25.733-9.4 36.4-6.267 10.533-15.067 18.8-26.4 24.8-11.333 5.867-24.4 8.8-39.2 8.8h-57V0zm56.2 127.2c12.267 0 23-2.4 32.2-7.2 9.2-4.933 16.333-11.667 21.4-20.2 5.067-8.667 7.6-18.6 7.6-29.8s-2.533-21.067-7.6-29.6c-5.067-8.667-12.2-15.4-21.4-20.2-9.2-4.933-19.933-7.4-32.2-7.4h-41.4v114.4h41.4z"/>
    </svg>

    <loader class="mx-2" :size="height + 'px'" :cycles="1" />

    <svg xmlns="http://www.w3.org/2000/svg" :height="height" fill="none" viewBox="0 0 115 140">
      <path fill="#fff" d="M50.078 12.8H.878V0h113.2v12.8h-49.2V140h-14.8V12.8z"/>
    </svg>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  name: 'Brand',

  components: {
    Loader
  },

  props: {
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: null,
    }
  },

  methods: {
    goto(path) {
      if (this.$route.path != path)
        this.$router.push(path);
    }
  }
}
</script>