module.exports =
[
  {
    type: 'Text',
    heading: 'Summary',
    body: ''
  },
  {
    type: 'Text',
    heading: 'Time Complexity:',
    body: ''
  },
  {
    type: 'Component',
    component: 'Sorting',
    sortType: 'bubble-sort',
  },
  {
    type: 'Code',
    heading: 'Implementation',
    body: "bubbleSort(A) {\n" +
      "\tlet key;\n" +
      "\tlet j;\n" +
      "\tfor (let i = 1; i < A.length; i++) {\n" +
      "\t\tkey = A[i];\n" +
      "\t\tj = i - 1;\n" +
      "\n" +
      "\t\twhile (j >= 0 && A[j] > key) {\n" +
      "\t\t\tA[j + 1] = A[j];\n" +
      "\t\t\tj--;\n" +
      "\t\t}\n" +
      "\t\tA[j + 1] = key;\n" +
      "\t}\n" +
      "}"
  },
  {
    type: 'Text',
    heading: 'Conclusion',
    body: ''
  }
]