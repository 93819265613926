<template>
  <div class="px-5">
    <div class="my-5 d-flex mx-auto justify-center" style="width: 100%; max-width: 500px;">
      <div style="position: relative;">
        <h1 class="ma-0">Steam Party</h1>
        <v-btn fab class="" x-small @click="help=!help" absolute top right style="z-index: 0; right: -30px; top: -12px;" icon><v-icon color="primary">mdi-help</v-icon></v-btn>
      </div>
    </div>

    <v-card v-if="help" class="mx-auto mb-5" style="max-width: 400px; font-size: 14px;">
      The easiest way to get your Steam ID or Vanity URL is to:<br>
      1. Go to your Steam profile<br>
      2. Click the "Edit Profile" button<br>
      3. In the "GENERAL" section under the "CUSTOM URL" input it should say "Your profile will be available at: https://steamcommunity.com/id/{Steam ID or Vanity URL}"
    </v-card>

    <div class="d-flex flex-column justify-center align-center mx-auto mb-5">
      <div
        v-for="(player, i) in players"
        :key="'player' + i"
        style="width: 100%; max-width: 400px;"
      >
        <v-card class="d-flex pa-0 ma-0 justify-center" style="background: transparent" tile flat>
          <img class="mr-2 my-auto":src="players[i].avatarfull" width="40" height="40" />
          <v-combobox
            :ref="'player' + i"
            v-model="players[i].id"
            :items="Object.keys(pastIds)"
            placeholder="Player 17 digit Steam ID or Vanity URL"
            class="px-0 mx-0"
            style="max-width: 300px;"
            :label="'Steam ID: ' + players[i].steamid"
          >
            <template slot="item" slot-scope="data">
              <img class="mr-2 my-auto":src="pastIds[data.item]" width="25" height="25" /> {{ data.item }} <span v-if="findVanity(data.item)">/{{ findVanity(data.item) }}</span>
            </template>
          </v-combobox>
          <v-btn color="red" fab  x-small style="right: -16px;" @click="removeItem(i);"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-card>
      </div>

      <div class="d-flex justify-space-around">
        <v-btn
          color="primary"
          class="mt-5 mx-3"
          style="color: white;"
          v-on:click="addPlayer()"
          tile
          :disabled="loading"
        >
          + Add Player
        </v-btn>
        <v-btn
          color="green"
          class="mt-5 mx-3"
          style="color: white;"
          v-on:click="findGames()"
          tile
          :disabled="loading || loadingAppList"
        >
          Find Games
        </v-btn>
      </div>

      <div v-if="loading" class="mt-10 d-flex flex-column align-center justify-center" style="max-width: 700px;">
        <h2 v-if="loadingTitle" style="color: black;">{{ loadingTitle }}</h2>
        <div class="my-5 lds-ring"><div></div><div></div><div></div><div></div></div>
        <h5 v-if="loadingSubtitle" style="color: black;">{{ loadingSubtitle }}</h5>
      </div>

      <div v-if="loadingAppList" class="mt-10 d-flex flex-column align-center justify-center" style="max-width: 700px;">
        <h1>Downloading the entire Steam game library</h1>
        <div class="my-5 lds-ring"><div></div><div></div><div></div><div></div></div>
        <h5>This could take 1 second to several minutes depending on your internet connection.</h5>
      </div>

      <table
        v-if="shared && shared.length > 0"
        class="mt-5"
        :key="shared.length + '' + shared[0].playtimes.length"
      >
        <thead>
          <tr class="d-flex align-center justify-center text-center noselect">
            <th 
              style="width: 150px; cursor: pointer;"
              class="d-flex align-center justify-center"
              @click="sortColumn(-1)"
            >
              <div>Game Name</div>
              <v-icon :id="'TableHead-1'" v-if="selectedColumn === -1" color="white" size="14" class="ml-2 arrow-down">mdi-triangle</v-icon>
            </th>
            <th
              v-for="(player, i) in players"
              :key="'playerHead' + i"
              style="width: 75px; cursor: pointer;"
              class="d-flex align-center justify-center"
              @click="sortColumn(i)"
            >
              <img :src="players[i].avatarfull" width="30" height="30" />
              <v-icon :id="'TableHead' + i" v-if="selectedColumn === i" color="white" size="14" class="ml-2 arrow-down">mdi-triangle</v-icon>
            </th>
            <th
              style="width: 125px; cursor: pointer;"
              class="d-flex align-center justify-center"
              @click="sortColumn(players.length)"
            >
              <div>Total Playtime</div>
              <v-icon :id="'TableHead' + players.length" v-if="selectedColumn === players.length" color="white" size="14" class="ml-2 arrow-down">mdi-triangle</v-icon>
            </th>
            <th
              style="width: 125px; cursor: pointer;"
              class="d-flex align-center justify-center"
              @click="sortColumn(players.length+1)"
            >
              <div>SP Rating</div>
              <v-icon :id="'TableHead' + (players.length+1)" v-if="selectedColumn === players.length+1" color="white" size="14" class="ml-2 arrow-down">mdi-triangle</v-icon>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(game, j) in shared"
            :key="'game' + j"
            class="d-flex align-center"
          >
            <td style="width: 150px"><b>{{ game.name }}</b></td>
            <td
              v-for="(time, k) in game.playtimes"
              :key="'playtime' + j + '' + k"
              style="width: 75px; text-align: center"
            >{{ Math.floor(time / 60)}}h</td>
            <td style="width: 125px; text-align: center">{{ Math.floor(game.playtime_forever / 60) }}h</td>
            <td style="width: 125px; text-align: center">{{ Math.floor(game.std) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div
      v-if="entry"
      class="d-flex align-center justify-center"
      style="top: 0px; left: 0px; height: 100%; width: 100%; position: fixed; background: #000000ee; z-index: 1;"
    >
      <div class="d-flex flex-column align-center justify-center" style="max-width: 700px;">
        <h2 style="color: white;">Please download the Steam game library from here and then upload it for future uses. The file is ~7MB so it may take about a minute depending on your internet connection.</h2>
        <div
          class="d-flex justify-center mt-10"
          style="width: 100%;"
        >
          <v-btn
            class="ma-2"
            outlined
            tile
            color="secondary"
            @click="downloadGameFile()"
          ><v-icon class="mr-1">mdi-download</v-icon>Download</v-btn>
          
          
          <v-btn
            class="ma-2 d-flex align-center"
            outlined
            tile
            color="white"
            @click="uploadGameFile()"
          ><v-file-input v-model="gameFile" id="fileUpload" type="file" accept="application/JSON" class="pa-0 ma-0" hide-input hide-details dark color="success" @change="loadGameFile()" />Upload</v-btn>
        </div> -->
        <!-- <v-btn
          class="mt-10"
          outlined
          tile
          color="white"
          @click="entry=false; loadingAppList=true;"
        >Continue<v-icon class="ml-1">mdi-arrow-right-bold</v-icon></v-btn> -->
      <!-- </div> -->
    <!-- </div> -->

    <!-- <div
      v-if="loadingAppList"
      class="d-flex align-center justify-center"
      style="top: 0px; left: 0px; height: 100%; width: 100%; position: fixed; background: #000000ee; z-index: 1;"
    >
      <div class="d-flex flex-column align-center justify-center" style="max-width: 700px;">
        <h1 style="color: white;">Downloading the entire Steam game library</h1>
        <div class="my-5 lds-ring"><div></div><div></div><div></div><div></div></div>
        <h5 style="color: white;">This could take from 10 seconds to several minutes depending on your internet connection.</h5>
      </div>
    </div> -->

    <div
      v-if="errorMessage"
      class="d-flex align-center justify-center"
      style="top: 0px; left: 0px; height: 100%; width: 100%; position: fixed; z-index: 1;"
    >
      <v-card style="max-width: 500px;">
        <v-card-title style="color: red;">Error</v-card-title>
        <v-card-text style="white-space: break-spaces;">{{ errorMessage }}</v-card-text>
        <v-card-actions class="d-flex justify-end"><v-btn outlined tile @click="errorMessage=null">Close</v-btn></v-card-actions>
      </v-card>
    </div>

    <div
      v-if="!privacyAccepted"
      class="d-flex align-center justify-center"
      style="top: 0px; left: 0px; height: 100%; width: 100%; position: fixed; z-index: 1;"
    >
      <v-card style="max-width: 500px;">
        <v-card-title>Privacy Policy</v-card-title>
        <v-card-text style="white-space: break-spaces;"><div style="width: 100%; max-height: 400px;">{{ privacyPolicy }}</div></v-card-text>
        <v-card-actions class="d-flex justify-end"><v-btn outlined tile color="green" @click="acceptPrivacy()">Accept</v-btn></v-card-actions>
      </v-card>
    </div>

  </div>
</template>

<script>
/* IMPORTS */
const privacyPolicy = require('../../assets/steamPrivacyPolicy.js')

export default {
  name: 'SteamParty',

  components: {

  },

  props: {

  },

  data: () => ({
    players: [{id:''},{id:''},{id:''}],
    idMap: {},
    applist: [],
    loadingAppList: false,
    loading: false,
    loadingTitle: '',
    loadingSubtitle: '',
    shared: [],
    selectedColumn: -2,
    tableKey: 0,
    pastIds: {},
    pastVanities: {},
    errorMessage: '',
    collected: false,
    entry: true,
    gameFile: null,
    privacyAccepted: false,
    help: false,
    env: null,
  }),

  methods: {
    findSTD(playtime_forever, playtimes) {
      const avg = Math.floor(playtime_forever / playtimes.length);
      let max = 60;
      playtimes.forEach(playtime => {
        const STD = Math.abs(playtime - avg)
        if (STD > max)
          max = STD
      });
      return playtime_forever / max;
    },

    acceptPrivacy() {
      this.privacyAccepted = true;
      localStorage.setItem('privacyAccepted', true);
      this.downloadGameFile();
    },
    
    downloadGameFile() {
      this.entry = false;
      this.loadingAppListTimeout = setTimeout(() => {
        this.loadingAppList = true;
      }, 1000);
      fetch('https://s3-us-west-2.amazonaws.com/3dot.io/steamAppList.json', {
        method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
      })
      .then((res) => {
        clearTimeout(this.loadingAppListTimeout);
        this.loadingAppList = false;
        if (!res.ok) {
          console.error(res);
        }
        else {
          res.json().then((data) => {
            this.applist = data.applist.apps.sort((a,b) => (a.appid < b.appid) ? 1 : ((b.appid > a.appid) ? -1 : 0));

            // const element = document.createElement('a');
            // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.applist)));
            // element.setAttribute('download', 'steam-party-game-list.json');
            // element.style.display = 'none';
            // document.body.appendChild(element);
            // element.click();
          })
        }
      })
      .catch((err) => {
        console.error(err);
      })
    },

    uploadGameFile() {
      document.getElementById('fileUpload').click();
      document.getElementById('fileUpload').blur()
    },

    loadGameFile() {
      if (this.gameFile.length === 0)
        return;
      const file = this.gameFile;
      const fr = new FileReader();
      fr.onload = (e) => {
        const file = e.target.result;
        this.applist = JSON.parse(file);
        this.entry = false;
      }
      fr.onerror = (e) => this.showError(e);
      fr.readAsText(file);
    },

    findVanity(steamid) {
      let flag = false;
      Object.keys(this.pastVanities).forEach(key => {
        if (this.pastVanities[key] === steamid)
          flag = key;
      });
      return flag;
    },
    
    addPlayer() {
      this.players.push({id: ''});
    },
    
    removeItem(i) {
      this.players.splice(i, 1);
      for (let j = 0; j < this.shared.length; j++)
        this.shared[j].playtimes.splice(i, 1);
      if (this.shared.length > 0 && this.players.length > 1)
        this.getShared(this.shared);
      this.loading = false;
    },

    sortColumn(c) {
      if (this.selectedColumn === c) {
        if (document.getElementById('TableHead' + c).classList.contains('arrow-down')) {
          document.getElementById('TableHead' + c).classList.replace('arrow-down', 'arrow-up');
          if (c === -1)
            this.shared.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
          else if (c < this.players.length)
            this.shared.sort((a,b) => (a.playtimes[c] > b.playtimes[c]) ? 1 : ((b.playtimes[c] > a.playtimes[c]) ? -1 : 0));
          else if (c === this.players.length)
            this.shared.sort((a,b) => (a.playtime_forever > b.playtime_forever) ? 1 : ((b.playtime_forever > a.playtime_forever) ? -1 : 0));
          else
            this.shared.sort((a,b) => (a.std > b.std) ? 1 : ((b.std > a.std) ? -1 : 0));
        }
        else if (document.getElementById('TableHead' + c).classList.contains('arrow-up')) {
          document.getElementById('TableHead' + c).classList.replace('arrow-up', 'arrow-down');
          if (c === -1)
            this.shared.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          else if (c < this.players.length)
            this.shared.sort((a,b) => (a.playtimes[c] < b.playtimes[c]) ? 1 : ((b.playtimes[c] < a.playtimes[c]) ? -1 : 0));
          else if (c === this.players.length)
            this.shared.sort((a,b) => (a.playtime_forever < b.playtime_forever) ? 1 : ((b.playtime_forever < a.playtime_forever) ? -1 : 0));
          else
            this.shared.sort((a,b) => (a.std < b.std) ? 1 : ((b.std < a.std) ? -1 : 0));
        }
      }
      else {
        this.selectedColumn = c;
        setTimeout(() => {
          document.getElementById('TableHead' + c).classList.replace('arrow-up', 'arrow-down');
          if (c === -1)
            this.shared.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          else if (c < this.players.length)
            this.shared.sort((a,b) => (a.playtimes[c] < b.playtimes[c]) ? 1 : ((b.playtimes[c] < a.playtimes[c]) ? -1 : 0));
          else if (c === this.players.length)
            this.shared.sort((a,b) => (a.playtime_forever < b.playtime_forever) ? 1 : ((b.playtime_forever < a.playtime_forever) ? -1 : 0));
          else
            this.shared.sort((a,b) => (a.std < b.std) ? 1 : ((b.std < a.std) ? -1 : 0));
        }, 0);
      }
    },

    getShared() {
      this.loading = true;
      this.loadingTitle = 'Finding shared games';

      setTimeout(() => {
        const shared = [];
        let sharedTime = 0;
        let playtimes = [];
        for (const game of this.players[0].games) {
          let allHave = true;
          sharedTime = game.playtime_forever;
          playtimes = [game.playtime_forever];

          for (let i = 1; i < this.players.length; i++) {
            const playtime = this.gameExists(this.players[i], game.appid);
            if (!playtime) {
              allHave = false;
              break;
            }
            else {
              playtimes.push(playtime)
              sharedTime += playtime;
            }
          }

          if (allHave) {
            shared.push({
              appid: game.appid,
              playtimes: playtimes,
              playtime_forever: sharedTime,
              std: this.findSTD(sharedTime, playtimes)
            });
          }
        }

        this.loadingTitle = 'Creating an easy to read list for you!'
        for (let i = 0; i < shared.length; i++) {
          for (let j = 0; j < this.applist.length; j++) {
            if (this.applist[j].appid == shared[i].appid) {
              shared[i].name = this.applist[j].name;
              break;
            }
            if (j == this.applist.length - 1) {
              shared[i].name = 'N/A';
            }
          }
        }
        this.loading = false;
        this.collected = true;
        shared.sort((a,b) => (a.std < b.std) ? 1 : ((b.std < a.std) ? -1 : 0));
        this.selectedColumn = this.players.length+1;
        this.shared = shared;
      }, 0);
    },

    findGames() {
      setTimeout(() => {
        for (let i = this.players.length - 1; i >= 0; i--)
          if (this.players[i] == null || this.players[i].id == null || this.players[i].id == '')
            this.players.splice(i, 1);

        if (this.players.length === 0)
          return this.showError('You cannot find games for 0 players.');

        this.loading = true;

        if (localStorage.getItem('pastIds') != null)
          this.pastIds = JSON.parse(localStorage.getItem('pastIds'));

        if (localStorage.getItem('pastVanities') != null)
          this.pastVanities = JSON.parse(localStorage.getItem('pastVanities'))

        const vanityurls = [];
        let steamids = '';
        for (let i = 0; i < this.players.length; i++) {
          console.log(this.players[i].id)
          if (this.pastIds[this.players[i].id] != null) {
            if (this.players[i].id != this.players[i].steamid)
              this.players[i] = {id: this.players[i].id};
            this.players[i].steamid = this.players[i].id;
            this.players[i].avatarfull = this.pastIds[this.players[i].id];
          }
          else if (this.pastIds[this.pastVanities[this.players[i].id]] != null) {
            if (this.pastVanities[this.players[i].id] != this.players[i].steamid)
              this.players[i] = {id: this.players[i].id};
            this.players[i].steamid = this.pastVanities[this.players[i].id];
            this.players[i].avatarfull = this.pastIds[this.pastVanities[this.players[i].id]];
          }
          else if (!/^\d{17}$/.test(this.players[i].id) && (this.players[i].steamid == null || this.pastVanities[this.players[i].id] != this.players[i].steamid)) {
            this.players[i] = {id: this.players[i].id};
            vanityurls.push(this.players[i].id);
            this.players[i].vanityurl = this.players[i].id;
          }
          else if (this.players[i].steamid == null) {
            this.players[i] = {id: this.players[i].id};
            this.players[i].steamid = this.players[i].id;
            steamids += this.players[i].id + ',';
          }
        }

        this.loadingTitle = 'Converting Vanity URL to SteamID';
        const reqIds = this.getSteamIds(vanityurls);
        reqIds.then(idMap => {
          this.idMap = idMap;
          this.pastVanities = Object.assign(idMap, this.pastVanities);
          localStorage.setItem('pastVanities', JSON.stringify(this.pastVanities));

          Object.keys(idMap).forEach(key => {
            for (let i = 0; i < this.players.length; i++) {
              if (idMap[this.players[i].vanityurl] != null) {
                this.players[i].steamid = idMap[this.players[i].vanityurl];
              }
            }
            if (this.pastIds[idMap[key]] == null)
              steamids += idMap[key] + ',';
          });
          steamids = steamids.slice(0, -1);
          
          this.loadingTitle = 'Retrieving player profiles';
          const reqSummaries = this.getSummaries(steamids);
          reqSummaries.then(summaries => {
            for (let summary of summaries) {
              if (this.pastIds[summary.steamid]) 
                this.pastIds[summary.steamid] = summary.avatarfull;
              else
                this.pastIds[summary.steamid] = summary.avatarfull

              let markforremoval = [];
              for (let i = 0; i < this.players.length; i++) {
                if (summary.communityvisibilitystate !== 3) {
                  this.showError(this.players[i] + ' does not have a public profile.')
                  markforremoval.unshift(i);
                }
                if (summary.steamid == this.players[i].steamid) {
                  this.players[i] = Object.assign(summary, this.players[i]);
                  break;
                }
              }

              for (let i of markforremoval) {
                this.players.slice(i, 1);
              }
            }
            localStorage.setItem('pastIds', JSON.stringify(this.pastIds));
            this.players.push({id: ''})
            this.players.pop();
            
            this.loadingTitle = 'Retrieving players game lists';
            const reqGames = this.getGames();
            reqGames.then(_ => {
              this.getShared();
            }).catch(err => {
              this.showError(err);
            })
          }).catch(err => {
            this.showError(err);
          })
        }).catch(err => {
          this.showError(err);
        })
      }, 0);
    },

    showError(msg) {
      this.loading = false;
      console.error(msg);
      this.errorMessage = msg;
    },
    
    gameExists(player, appid) {
      for (const game of player.games)
        if (game.appid === appid)
          return game.playtime_forever;
      return false;
    },

    getGames(i=0) {
      return new Promise((resolve, reject) => {
        if (i >= this.players.length)
          return resolve();

        if (this.players[i].games != null)
          return resolve(this.getGames(i+1));

        console.log('Getting game list for: ' + this.players[i].steamid);
        fetch('https://qsm3cnlf30.execute-api.us-west-2.amazonaws.com/' + this.env + '/v1/steam/games?steamid=' + this.players[i].steamid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then((res) => {
          if (!res.ok) {
            return reject(res);
          }
          else {
            res.json().then((data) => {
              if (!data.body.response.games)
                return reject('We are having trouble getting the Steam player game lists you are requesting.\n\nThe player\'s profile may not be set to public or the Steam Web API may be under maintenance.\n\nPlease ask your friend to check their profile settings or try again in about an hour to see if the problem has resolved.');

              this.players[i].games = data.body.response.games;
              this.players[i].games_count = data.body.response.game_count;
              return resolve(this.getGames(i+1));
            })
          }
        })
        .catch((err) => {
          return reject(err);
        })
      })
    },

    getSummaries(steamids) {
      return new Promise((resolve, reject) => {
        if (steamids.length === 0)
          return resolve([]);

        console.log('Getting profile summaries for: ' + steamids);
        fetch('https://qsm3cnlf30.execute-api.us-west-2.amazonaws.com/' + this.env + '/v1/steam/summaries?steamids=' + steamids, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then((res) => {
          if (!res.ok) {
            return reject(res);
          }
          else {
            res.json().then((data) => {
              if (!data.body.response.players)
                return reject('We are having trouble getting the Steam player profiles you are requesting.\n\nThe player\'s profile may not be set to public or the Steam Web API may be under maintenance.\n\nPlease have your friend double check their profile privacy settings or try again in about an hour to see if the problem has resolved.');

              return resolve(data.body.response.players);
            })
          }
        })
        .catch((err) => {
          return reject(err);
        })
      })
    },

    getSteamIds(vanityurls, idMap={}) {
      return new Promise((resolve, reject) => {
        if (vanityurls.length === 0)
          return resolve(idMap);

        const vanityurl = vanityurls.pop();
        console.log('Getting SteamID for: ' + vanityurl);
        fetch('https://qsm3cnlf30.execute-api.us-west-2.amazonaws.com/' + this.env + '/v1/steam/id?vanityurl=' + vanityurl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then((res) => {
          if (!res.ok) {
            return reject(res);
          }
          else {
            res.json().then((data) => {
              if (!data.body.response.steamid)
                return reject('We are having troubcle converting the vanity URL you are requesting.\n\nThe Vanity URL may not exist or the Steam Web API may be under maintenance.\n\nPlease have your friend double check their profile to find their Vanity URL or try again in about an hour to see if the problem has resolved.');

              if (data.body.response.success === 1)
                idMap[vanityurl] = data.body.response.steamid;
              return resolve(this.getSteamIds(vanityurls, idMap));
            })
          }
        })
        .catch((err) => {
          return reject(err);
        })
      })
    }
  },

  created() {
    this.$emit('footer', true);
    this.$emit('toolbar', true);

    console.log(window.location.hostname);
    const hostname = window.location.hostname;
    if (hostname === '3dot.io') {
      this.env = 'prod';
    }
    else if (hostname === 'localhost' || hostname === '127.0.0.1') {
      this.env = 'dev';
      // this.privacyAccepted = true;      
    }

    // this.privacyAccepted = localStorage.getItem('privacyAccepted');
    this.privacyPolicy = privacyPolicy;

    if (localStorage.getItem('pastIds') != null)
      this.pastIds = JSON.parse(localStorage.getItem('pastIds'));
    
    if (localStorage.getItem('pastVanities') != null)
      this.pastVanities = JSON.parse(localStorage.getItem('pastVanities'))
  }
}
</script>

<style scoped>
.arrow-down {
  transform: scaleY(-1);
}

.arrow-up {
  transform: scaleY(1);
}

table {
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  table-layout: fixed;
}
thead tr {
  background-color: var(--primary);
  color: #ffffff;
  text-align: left;
  display: table-header-group;
}

th, td {
  padding: 12px 15px;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
