<template>
  <div>
    <div :style="'color: white; height: ' + imageHeight + 'px; width: 100%; overflow: hidden;'">
      <div class="d-flex flex-column align-center justify-center"style="width: 100%; height: 100%; position: relative; top: 0; left: 0;">
        <img height="auto" width="100%" style="position: absolute;" srcset="img/meeting/min-meeting-16:9h-3840x2160.webp 3840w,img/meeting/min-meeting-16:9h-3840x2160.jpg 3840w,img/meeting/min-meeting-16:9h-2560x1440.webp 2560w,img/meeting/min-meeting-16:9h-2560x1440.jpg 2560w,img/meeting/min-meeting-16:9-1920x1080.webp 1920w,img/meeting/min-meeting-16:9-1920x1080.jpg 1920w,img/meeting/min-meeting-16:9-1280x720.webp 1280w,img/meeting/min-meeting-16:9-1280x720.jpg 1280w,img/meeting/min-meeting-16:9-960x540.webp 960w,img/meeting/min-meeting-16:9-960x540.jpg 960w,img/meeting/min-meeting-16:9-640x360.webp 640w,img/meeting/min-meeting-16:9-640x360.jpg 640w" />
        <h1 style="z-index: 2;">Contact Us</h1>
      </div>
    </div>

    <div 
      class="mx-auto mt-10 d-flex flex-column align-center px-4
      flex-md-row justify-center"
      style="max-width: 1000px;"
    >
      <div
        class="mx-auto text-center text-md-left"
        style="width: 100%; max-width: 400px; height: 100%;"
      >
        <h5>Prefer to use an email client? Click <a href="mailto:brady@3dot.io">here</a> to send us an email.</h5>
        <p></p>
        <ul>

        </ul>
      </div>
      
      <div class="pa-3" />

      <v-card
        style="width: 100%; max-width: 448px; background: #f2f2f2;"
        tile
      >
        <h2 class="mb-3 text-center">How can we help?</h2>
        <v-form
          ref="form"
          style="width: 100%;"
          @submit="sendMail"
        >
          <v-text-field
            v-model="name"
            label="Name"
            :rules="[rules.required]"
          />
          <v-text-field
            v-model="email"
            label="Email"
            :rules="[rules.required, rules.email]"
          />
          <v-textarea
            v-model="body"
            class="mt-4"
            style="width: 100%; border-radius: 0 !important;"
            placeholder="Message"
            rows=5
            outlined
            auto-grow
            :rules="[rules.required]"
          />
          <div
            class="d-flex flex-column align-center"
            style="width: 100%;"
          >
            <v-btn :loading="sending" tile type="submit" color="primary" class="mx-auto" style="width: 120px;" @click="sendMail">
              <span>Send</span>
            </v-btn>
          </div>
        </v-form>
      </v-card>

    </div>
  </div>
</template>

<script>
// import ComponentName from '../components/ComponentName.vue'

export default {
  name: 'Contact',

  components: {

  },

  props: {
    env: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    imageHeight: 400,
    name: null,
    email: null,
    body: null,
    sending: false,
    rules: null,
  }),

  watch: {

  },

  methods: {
    async sendMail(e) {
      e.preventDefault();
      this.$refs.form.validate();
      if (!this.name || !this.email || !this.body) {
        return;
      }

      this.sending = true;
      await fetch('https://qsm3cnlf30.execute-api.us-west-2.amazonaws.com/' + this.env + '/v1/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          body: this.body,
        }),
      })
      .then((res) => {
        if (!res.ok) {
          console.error(res);
          this.$emit('error', 'An unexpected error occured. Use your email client to send us a message at brady@3dot.io and let us know this happened.');
        }
        else {
          res.json().then((data) => {
            console.log(data);
            this.$emit('success', 'Your email has been sent! We will get back to you ASAP.');
            this.$refs.form.reset()
          })
        }
      })
      .catch((err) => {
        console.error(err);
        this.$emit('error', 'An unexpected error occured. Use your email client to send us a message at brady@3dot.io and let us know this happened.');
      })

      this.sending = false;
    },

    onresize(e) {
      const imageHeight = document.body.clientWidth * 9 / 16;
      if (imageHeight < 400) {
        this.imageHeight = imageHeight;
      }
      else {
        this.imageHeight = 400;
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.onresize);
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
    this.rules = {
      required: v => (v != null && v !== '') || 'Required field',
      email: v => (v == null || v == '') || /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || 'Format example@domain.com',
    };
    window.addEventListener('resize', this.onresize);
    this.onresize();
  },

  mounted () {
    
  },
};
</script>

<style>
  .v-text-field__details {
    padding: 0 0 !important;
  }
</style>
