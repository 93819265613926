module.exports =
[
  {
    type: 'Text',
    heading: 'Summary',
    body: 'Quicksort is a commonly used algorithm for sorting. When implemented well, it can be about two or three times faster than its main competitors, merge sort and heapsort.\n\n' + 
    'Quicksort is a divide-and-conquer algorithm. It works by selecting a pivot element and two iterators. The iterators move toward from the array and partitioning the other elements into two sub-arrays, according to whether they are less than or greater than the pivot. The sub-arrays are then sorted recursively. This can be done in-place, requiring small additional amounts of memory to perform the sorting.'
  },
  {
    type: 'Text',
    heading: 'Complexity Analysis: Ω(n logn) - O(n2)',
    body: 'Mathematical analysis of quicksort shows that, on average, the algorithm takes O(n log n) comparisons to sort n items. In the worst case, it makes O(n2) comparisons.'
  },
  {
    type: 'Text',
    heading: 'Space Constraint: O(logn)',
    body: 'Each partition that is stored in the recursive algorithm would require O(logn) space.'
  },
  {
    type: 'Component',
    component: 'Sorting',
    sortType: 'quicksort',
  },
  {
    type: 'Code',
    heading: 'Implementation',
    body: "quicksort(A, lo, hi) {\n" +
      "\tif (lo < hi) {\n" +
      "\t\tlet p = partition(A, lo, hi);\n" +
      "\t\tquicksort(A, lo, p - 1);\n" +
      "\t\tquicksort(A, p + 1, hi);\n" +
      "\t}\n" +
      "}\n" +
      "\n" +
      "partition(A, lo, hi) {\n" +
      "\tlet pivot = A[hi];\n" +
      "\tlet i = lo;\n" +
      "\tfor (let j = lo; j < hi; j++) {\n" +
      "\t\tif (A[j] < pivot) {\n" +
      "\t\t\tswap(A, i, j);\n" +
      "\t\t\ti = i + 1;\n" +
      "\t\t}\n" +
      "\t}\n" +
      "\tswap(A, i, hi);\n" +
      "\treturn i;\n" +
      "}\n" +
      "\n" +
      "swap(A, i, j) {\n" +
      "\tconst tmp = A[i];\n" +
      "\tA[i] = A[j];\n" +
      "\tA[j] = tmp;\n" +
      "}"
  },
  {
    type: 'Text',
    heading: 'Conclusion',
    body: 'We can see from the examples above that quicksort does the worst with already sorted datasets, then with datasets sorted the opposite way, and finally, the best with random datasets. Use quicksort when dealing with datasets that have a high probablility of being randomized. This sort will increase in compute time the more sorted the dataset is.'
  }
]