<template>
  <div>
    <div
      id="section1"
      class="webpages d-flex align-center justify-center flex-column"
      style="color: white; overflow: hidden;"
    >
      <img
        id="section1Image"
        :width="section1ImageW"
        :height="section1ImageH"
        style="position: absolute; left: 0;"
        src="img/laptop-3174729/min-laptop-3174729-16:9-640x360.jpg"
        srcset="img/laptop-3174729/min-laptop-3174729-16:9h-3840x2160.webp 3840w,img/laptop-3174729/min-laptop-3174729-16:9h-3840x2160.jpg 3840w,img/laptop-3174729/min-laptop-3174729-16:9h-2560x1440.webp 2560w,img/laptop-3174729/min-laptop-3174729-16:9h-2560x1440.jpg 2560w,img/laptop-3174729/min-laptop-3174729-16:9-1920x1080.webp 1920w,img/laptop-3174729/min-laptop-3174729-16:9-1920x1080.jpg 1920w,img/laptop-3174729/min-laptop-3174729-16:9-1280x720.webp 1280w,img/laptop-3174729/min-laptop-3174729-16:9-1280x720.jpg 1280w,img/laptop-3174729/min-laptop-3174729-16:9-960x540.webp 960w,img/laptop-3174729/min-laptop-3174729-16:9-960x540.jpg 960w,img/laptop-3174729/min-laptop-3174729-16:9-640x360.webp 640w,img/laptop-3174729/min-laptop-3174729-16:9-640x360.jpg 640w"
      />

      <div class="d-flex align-center justify-center flex-column">
        <h1 style="z-index: 2;">We can help you find a solution to fit your web needs.</h1>
        <v-btn @click="scrollTo('section2')" text tile><v-icon color="white" x-large>mdi-chevron-down</v-icon></v-btn>
      </div>
    </div>

    <hr class="my-10">

    <div
      id="section2"
      class="d-flex justify-center align-center flex-wrap px-3"
    > 
      <v-card
        v-for="(item, i) in offerings"
        :key="'offering' + i"
        tile
        class="ma-5 mt-0 offering pa-0 pb-3"
        style="overflow: hidden;"
      >
        <v-img
          class="offering-pic"
          height="250"
          :src="'img/'+item.src+'/min-'+item.src+'-3:2-540x360.jpg'"
          :srcset="'img/'+item.src+'/min-'+item.src+'-3:2-1080x720.webp 1080w,img/'+item.src+'/min-'+item.src+'-3:2-1080x720.jpg 1080w,img/'+item.src+'/min-'+item.src+'-3:2-720x480.webp 720w,img/'+item.src+'/min-'+item.src+'-3:2-720x480.jpg 720w,img/'+item.src+'/min-'+item.src+'-3:2-540x360.webp 540w,img/'+item.src+'/min-'+item.src+'-3:2-540x360.jpg 540w'"
        />

        <v-card-title>{{ item.title }}</v-card-title>

        <v-card-text class="pb-0">
          <span>{{ item.text }}</span>
          <ul
            class="my-5"
          >
            <li
              v-for="(point, j) in item.points"
              :key="'point' + i + '-' + j"
            >{{ point }}</li>
          </ul>
        </v-card-text>
        
        <!-- <v-card-actions class="pa-0 px-5 pb-5 d-flex">
          <v-btn class="ml-auto mr-0" color="primary" tile>Select</v-btn>
        </v-card-actions> -->

      </v-card>
    </div>
    
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'NeedAWebsite',

  components: {

  },

  props: {

  },

  data: () => ({
    section1ImageW: 'auto',
    section1ImageH: '100%',
    offerings: [
      {
        title: 'Personal',
        src: 'personal',
        text: 'Boost your networth by putting yourself into the online space.',
        points: [
          'Online Resume',
          'Freelance Services',
        ],
      },
      {
        title: 'Small Business',
        src: 'small-business',
        text: 'Offer your customers the information they need to choose your business over your competitors.',
        points: [
          'First-time business website',
          'Upgrade to existing business site',
        ]
      },
      {
        title: 'User Management',
        src: 'user-management',
        text: 'Take away the tedious hassles of scouring spreadsheets.',
        points: [
          'Manage users and their data',
          'Offer payment solutions',
        ]
      },
      {
        title: 'Web Applications',
        src: 'web-apps',
        text: 'Need to automate a specific task?',
        points: [
          'Leveraging and transforming API data',
          'Remote or local microservices',
          'Scheduled script automation'
        ]
      }
    ]
  }),

  watch: {

  },

  computed: {

  },

  methods: {
    onresize(e) {
      if (document.getElementById('section1').clientWidth >= document.getElementById('section1').clientHeight * 16/9) {
        this.section1ImageW = '100%';
        this.section1ImageH = 'auto';
      }
      else {
        this.section1ImageW = 'auto';
        this.section1ImageH = '100%';
      }
      document.getElementById('section1').style.height = (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight) * 0.8 + 'px';
    },

    scrollTo(id) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 40,
        behavior: 'smooth',
      })
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.onresize);
  },

  created() {
    this.$emit('footer', true);
    this.$emit('toolbar', true);

    window.addEventListener('resize', this.onresize);
  },

  mounted() {
    this.onresize();
    this.onresize();
  }
}
</script>

<style>
  .offering {
    width: 100%;
    max-width: 360px;
    white-space: break-spaces;
    transition: 0.3s;
  }

  .offering:hover {
    cursor: pointer;
    filter: grayscale(0%);
  }

  .webpages {
    width: 100%;
    box-shadow: 0px 0px 10px 0px black;
    filter: grayscale(50%);
  }
</style>
