<template>
  <div class="dot-loader-parent" :style="'height:' + size + '; width:' + size + ';'" >
    <div class='dot-loader'>
      <div v-for="(dot, i) in dots" :key="i" :style="'animation-play-state: ' + dot.playState + ';'"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    size: {
      type: String,
      required: false,
      default: '40px',
    },
    cycles: {
      type: Number,
      required: false,
      default: -1,
    },
  },

  data: () => ({
    dots: [
      {
        playState: 'paused',
      },
      {
        playState: 'paused',
      },
      {
        playState: 'paused',
      },
    ]
  }),

  mounted() {
    if (this.cycles > 0) {
      const delay = 1000;

      setTimeout(() => {
        this.dots.forEach(dot => {
          dot.playState = '';
        });
      }, delay)
      
      setTimeout(() => {
        this.dots.forEach(dot => {
          dot.playState = 'paused';
        });
      }, delay + 3200 * this.cycles)
    }
    else if (this.cycles < 0) {
      this.dots.forEach(dot => {
        dot.playState = '';
      });
    }
  }
}
</script>

<style scoped>
  .dot-loader {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .dot-loader > * {
    position: absolute;
    width: 40%;
    height: 40%;
    border-radius: 100%;
    animation: dot-loader 3.2s cubic-bezier(0.5, 0, 0, 1) infinite;
  }
  .dot-loader > *:nth-child(1) {
    animation-delay: -1.6s;
    background: var(--blue);
  }
  .dot-loader > *:nth-child(2) {
    animation-delay: -0.8s;
    background: var(--yellow);
  }
  .dot-loader > *:nth-child(3) {
    animation-delay: 0s;
    background: var(--red);
  }

  @keyframes dot-loader {
    0% {
      top: 0; left: 0;
    }
    15%, 25% {
      top: 0; left: 60%;
    }
    40%, 50% {
      top: 60%; left: 60%;
    }
    65%, 75% {
      top: 60%; left: 0;
    }
    90%, 100% {
      top: 0; left: 0;
    }
  }
</style>