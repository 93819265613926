<template>
  <div>
    <!-- <h1 class=" pt-5 mx-auto text-center">We haven't made this page yet...</h1> -->
    <div class="mt-5">
      <div
        v-for="(item, i) in portfolio"
        :key="'portfolio' + i"
        class="d-flex justify-center flex-column mb-10"
        style="width: 100%;"
      >
        <h2 class="mb-3">{{item.title}}</h2>
        <div class="mx-auto" :style="'width: 100%; max-width: 1000px; height: ' + imageHeight + ';'">
          <img width="auto" :height="imageHeight" :srcset="'img/'+item.imgName+'/min-'+item.imgName+'-16:9h-3840x2160.webp 3840w,img/'+item.imgName+'/min-'+item.imgName+'-16:9h-3840x2160.jpg 3840w,img/'+item.imgName+'/min-'+item.imgName+'-16:9h-2560x1440.webp 2560w,img/'+item.imgName+'/min-'+item.imgName+'-16:9h-2560x1440.jpg 2560w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-1920x1080.webp 1920w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-1920x1080.jpg 1920w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-1280x720.webp 1280w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-1280x720.jpg 1280w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-960x540.webp 960w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-960x540.jpg 960w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-640x360.webp 640w,img/'+item.imgName+'/min-'+item.imgName+'-16:9-640x360.jpg 640w'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ComponentName from '../components/ComponentName.vue'

export default {
  name: 'Portfolio',

  components: {

  },

  props: {

  },

  data: () => ({
    imageHeight: 0,
    portfolio: [
      {
        title: 'Pete\'s Poutine',
        imgName: 'petespoutine-homepage',
        url: 'https://petespoutine.com',
      },
      {
        title: 'Mt. Baker Rim Member\'s Portal',
        imgName: 'mbr-homepage',
        url: 'https://portal.mtbakerrim.com',
      }
    ]
  }),

  watch: {

  },

  methods: {
    onresize(e) {
      if (document.body.clientWidth < 1000) {
        this.imageHeight = document.body.clientWidth * 9 / 16;
      }
      else {
        this.imageHeight = 1000 * 9 / 16;
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.onresize);
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
    window.addEventListener('resize', this.onresize);
    this.onresize();
  },

  mounted () {

  },
};
</script>

<style scoped>

</style>
