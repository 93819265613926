<template>
  <div>
    <!-- Section 1 -->
    <div
      id="section1"
      class="d-flex align-center justify-center flex-column section-1"
      style="overflow: hidden;"
    >
      <div class="d-flex flex-column justify-center" style="width: 100%; height: 100%; position: relative;">
        <img
          :width="section1ImageW"
          :height="section1ImageH"
          style="position: absolute;"
          src="img/web-1012467/min-web-1012467-16:9-640x360.jpg"
          srcset="img/web-1012467/min-web-1012467-16:9h-3840x2160.webp 3840w,img/web-1012467/min-web-1012467-16:9h-3840x2160.jpg 3840w,img/web-1012467/min-web-1012467-16:9h-2560x1440.webp 2560w,img/web-1012467/min-web-1012467-16:9h-2560x1440.jpg 2560w,img/web-1012467/min-web-1012467-16:9-1920x1080.webp 1920w,img/web-1012467/min-web-1012467-16:9-1920x1080.jpg 1920w,img/web-1012467/min-web-1012467-16:9-1280x720.webp 1280w,img/web-1012467/min-web-1012467-16:9-1280x720.jpg 1280w,img/web-1012467/min-web-1012467-16:9-960x540.webp 960w,img/web-1012467/min-web-1012467-16:9-960x540.jpg 960w,img/web-1012467/min-web-1012467-16:9-640x360.webp 640w,img/web-1012467/min-web-1012467-16:9-640x360.jpg 640w"
        />
        <div 
          class="d-flex flex-column justify-center mx-auto section-1-text"
          :style="'opacity: ' + section1Opacity + ';'"
        >
          <h1 class="mb-7">3DOT Offers Website Development and Content Management</h1>
          <v-btn
            class="mx-auto"
            color="white"
            to="/need-a-website"
            tile
          >
            Learn more
          </v-btn>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Section 2 -->
    <div
      id="section2"
      class="d-flex section-2"
    >
      <div 
        class="d-flex flex-column ma-auto section-2-text py-10"
        :style="'opacity: ' + section2Opacity + ';'"
      >
        <h1 class="text-center mb-7">Already have a website?</h1>
        <div
          class="d-flex flex-wrap justify-center"
        >
          <v-card-v1
            v-for="item in websiteServices"
            v-bind:key="item.title"
            max-width="350"
            class="ma-3"
            :title="item.title"
            :text="item.text"
            :icon="item.icon"
          >
          </v-card-v1>
        </div>
        <v-btn
          class="mt-10 mx-auto"
          color="primary"
          width="170"
          to="/contact"
          tile
        >
          Contact Us
        </v-btn>
      </div>
    </div>

    <hr class="my-5">

    <!-- Section 3 -->
    <div
      id="section3"
      class="d-flex align-center justify-center section-3"
      :style="'opacity: ' + section3Opacity + '; overflow: hidden;'"
    >
      <div class="d-flex flex-column justify-center" style="width: 100%; height: 100%; position: relative;">
        <img
          :width="section1ImageW"
          :height="section1ImageH"
          style="position: absolute;"
          src="img/server-hallway/min-server-hallway-16:9-640x360.jpg "
          srcset="img/server-hallway/min-server-hallway-16:9h-3840x2160.webp 3840w,img/server-hallway/min-server-hallway-16:9h-3840x2160.jpg 3840w,img/server-hallway/min-server-hallway-16:9h-2560x1440.webp 2560w,img/server-hallway/min-server-hallway-16:9h-2560x1440.jpg 2560w,img/server-hallway/min-server-hallway-16:9-1920x1080.webp 1920w,img/server-hallway/min-server-hallway-16:9-1920x1080.jpg 1920w,img/server-hallway/min-server-hallway-16:9-1280x720.webp 1280w,img/server-hallway/min-server-hallway-16:9-1280x720.jpg 1280w,img/server-hallway/min-server-hallway-16:9-960x540.webp 960w,img/server-hallway/min-server-hallway-16:9-960x540.jpg 960w,img/server-hallway/min-server-hallway-16:9-640x360.webp 640w,img/server-hallway/min-server-hallway-16:9-640x360.jpg 640w"
        />
        <div 
          class="d-flex flex-column justify-center mx-auto section-1-text"
        >
          <h1>AWS Cloud Management</h1>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Section 4 -->
    <div
      id="section4"
      class="d-flex flex-column section-4 py-10"
      :style="'opacity: ' + section4Opacity + ';'"
    >
      <h2
        class="mx-auto px-5 mb-7 text-center"
        style="line-height: 1.5em"
      >
        We offer management for several AWS services.
      </h2>
      <div
        id="AWSServiceCards"
        class="d-flex flex-wrap justify-center mx-auto"
        style="max-width: 1300px;"
      >
        <v-card-v1
          v-for="i in awsServiceCount"
          v-bind:key="awsServices[i-1].title"
          :title="awsServices[i-1].title"
          :text="awsServices[i-1].text"
          :icon="awsServices[i-1].icon"
          :link="awsServices[i-1].link"
        />
      </div>
      <div
        v-if="this.awsServices"
        class="mx-auto mt-10"
      >
        <v-btn
          v-if="!awsServiceShowMore"
          v-on:click="showMoreAWS()"
          color="primary"
          tile
        >
          Show More
        </v-btn>
        <v-btn
          v-else
          v-on:click="showLessAWS()"
          color="warning"
          tile
        >
          Show Less
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
/* Things 3DOT Offers
 * 1. Website Development & Hosting
 * 2. Website Management & Administration
 * 3. AWS Management (S3, EC2, API Gateways, Lambdas, RDS)
 */
import VCardV1 from '@/components/VCardV1.vue';

export default {
  name: 'Home',

  components: {
    VCardV1,
  },

  props: {

  },

  data: () => ({
    currentYear: null,
    section1Opacity: 0,
    section2Opacity: 0,
    section3Opacity: 0,
    section4Opacity: 0,
    websiteServices: [
      {
        title: 'Hosting',
        text: 'We will host your website on industry level servers to keep your website secure and running at optimal performance.',
        icon: 'mdi-server'
      },
      {
        title: 'Mangement',
        text: 'We will manage your website by updating content and applying minor updates to keep your website looking fresh and up-to-date.',
        icon: 'mdi-content-duplicate'
      }
    ],
    awsServices: [
      {
        title: 'RDS',
        text: 'Amazon Relational Database Service (Amazon RDS) makes it easy to set up, operate, and scale a relational database in the cloud.',
        icon: '',
        link: 'https://aws.amazon.com/rds/',
      },
      {
        title: 'WorkMail',
        text: 'Amazon WorkMail is a secure, managed business email and calendar service with support for existing desktop and mobile email client applications.',
        icon: '',
        link: 'https://aws.amazon.com/workmail/',
      },
      {
        title: 'EC2',
        text: 'Amazon Elastic Compute Cloud (Amazon EC2) is a web service that provides secure, resizable compute capacity in the cloud.',
        icon: '',
        link: 'https://aws.amazon.com/ec2/',
      },
      {
        title: 'S3',
        text: 'Object storage built to store and retrieve any amount of data from anywhere.',
        icon: '',
        link: 'https://aws.amazon.com/s3/',
      },
      {
        title: 'API Gateway',
        text: 'Amazon API Gateway is a fully managed service that makes it easy for developers to create, publish, maintain, monitor, and secure APIs at any scale.',
        icon: '',
        link: 'https://aws.amazon.com/api-gateway/',
      },
      {
        title: 'Lambda',
        text: 'AWS Lambda lets you run code without provisioning or managing servers. You pay only for the compute time you consume.',
        icon: '',
        link: 'https://aws.amazon.com/lambda/',
      },
      {
        title: 'Route 53',
        text: 'Amazon Route 53 is a highly available and scalable cloud Domain Name System (DNS) web service.',
        icon: '',
        link: 'https://aws.amazon.com/route53/',
      },
    ],
    awsServiceStartCount: 0,
    awsServiceShowMore: null,
    awsServiceCount: 0,
    section1ImageW: 'auto',
    section1ImageH: '100%',
  }),

  watch: {

  },

  methods: {
    showMoreAWS: function() {
      if (this.awsServiceCount + this.awsServiceStartCount <= this.awsServices.length)
        this.awsServiceCount += this.awsServiceStartCount;
      else
        this.awsServiceCount = this.awsServices.length;
      if (this.awsServiceCount == this.awsServices.length)
        this.awsServiceShowMore = true;

      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY + document.getElementById('AWSServiceCards').children[this.awsServiceCount-1].clientHeight,
          left: 0,
          behavior: 'smooth',
        })
      }, 0)
    },

    showLessAWS: function() {
      this.awsServiceCount = this.awsServiceStartCount;
      this.awsServiceShowMore = false;

      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById('AWSServiceCards').children[0].offsetTop - 100,
          left: 0,
          behavior: 'smooth',
        })
      }, 0)
    },

    updateShowAWS: function() {
      if (this.awsServiceCount != this.awsServiceStartCount)
        this.awsServiceCount = this.awsServiceStartCount;
      this.awsServiceShowMore = false;
    },

    onresize: function(e) {
      if (document.getElementById('section1').clientWidth >= document.getElementById('section1').clientHeight * 16/9) {
        this.section1ImageW = '100%';
        this.section1ImageH = 'auto';
      }
      else {
        this.section1ImageW = 'auto';
        this.section1ImageH = '100%';
      }

      // Section 1 height
      if (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight < 667) {
        document.getElementById('section1').style.height = (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight) * 1 + 'px';
        // document.getElementById('section1').style.height = '500px';
        document.getElementById('section3').style.height = (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight) * 1 + 'px';
        document.getElementById('section3').style.backgroundPosition = 'right center';
      }
      else {
        document.getElementById('section1').style.height = (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight) * 0.7 + 'px';
        // document.getElementById('section1').style.height = '500px';
        document.getElementById('section3').style.height = (window.innerHeight - document.getElementsByTagName('header')[0].clientHeight) * 0.7 + 'px';
        document.getElementById('section3').style.backgroundPosition = 'center center';
      }
      
      // Limit default amount of AWS Services shown
      const oldAWSService = this.awsServiceStartCount;
      if (document.body.clientWidth < 700)
        this.awsServiceStartCount = 1;
      else if (document.body.clientWidth < 1040)
        this.awsServiceStartCount = 2;
      else if (document.body.clientWidth >= 1040)
        this.awsServiceStartCount = 3;
      if (oldAWSService != this.awsServiceStartCount)
        this.updateShowAWS();
    },
    
    onscroll: function(e) {
      let y = window.scrollY;
      // Section 2 opacity
      if (y + window.innerHeight >= document.getElementById('section1').clientHeight + 200)
        this.section2Opacity = 1;

      // Section 3 opacity
      if (y + window.innerHeight >= document.getElementById('section1').clientHeight + document.getElementById('section2').clientHeight + 200)
        this.section3Opacity = 1;

      // Section 4 opacity
      if (y + window.innerHeight >= document.getElementById('section1').clientHeight + document.getElementById('section2').clientHeight + document.getElementById('section3').clientHeight + 400)
        this.section4Opacity = 1;
    }
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
  },

  destroyed () {
    window.removeEventListener('resize', this.onresize);
    window.removeEventListener('scroll', this.onscroll);
  },

  mounted () {
    window.addEventListener('resize', this.onresize);
    window.addEventListener('scroll', this.onscroll);
    setTimeout(()=>{
      this.section1Opacity = 1;
    }, 500);
    this.onresize();
    this.onresize();
    this.onscroll();
    this.onscroll();
  },
};
</script>

<style scoped>
  .color-transition {
    transition: background-color .5s ease;
  }
  .section-1 {
    width: 100%;
    box-shadow: 0px 0px 10px 0px black;
  }
  .section-1-text {
    color: #FFF;
    position: relative;
    transition: opacity 2s ease;
    top: -5%;
  }
  .section-2 {
    width: 100%;
    background: #E6e6e6;
  }
  .section-2-text {
    color: black;
    position: relative;
    transition: opacity 1s ease;
  }
  .section-3 {
    width: 100%;
    transition: opacity 2s ease;
    box-shadow: 0px 0px 10px 0px black;
  }
  .section-3-text {
    color: #fff;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 7px;
  }
  .section-4 {
    width: 100%;
    transition: opacity 2s ease;
  }
  .section-4-text {
    color: #000;
  }
</style>
