<template>
  <v-container
    class="d-flex flex-column"
    style="width: 350px;"
  >
    <h1 class="text-center mb-5">Time Calculator</h1>
    
    <div
      class="d-flex align-center justify-space-between"
      style="width: 100%;"
    >
      <div></div>
      <div>
        <b>{{ (totalTime/60).toFixed(2) }} hrs</b>
      </div>
    </div>

    <div
      class="d-flex align-center justify-space-between py-3"
      style="width: 100%;"
    > 
      <v-text-field
        v-model="wage"
        label="Wage"
        single-line
        prefix="Wage: $"
        style="max-width: 130px;"
        class="ma-0 pa-0"
        hide-details
        type="number"
      />
      <div>
        <b>${{ (totalTime/60*wage).toFixed(2) }}</b>
      </div>
    </div>

    <div
      class="d-flex justify-center my-3"
    >
      <v-btn small class="mx-5" v-on:click="loadTimes()" tile>Load</v-btn>
      <v-btn small class="mx-5" v-on:click="saveTimes()" :color="savedColor" :dark="saved" tile>Save</v-btn>
    </div>

    <hr
      v-if="times.length > 0"
      class="my-3"
    >

    <two-times
      v-for="time in times"
      v-bind:key="time.id"
      v-on:update="updateValue($event)"
      v-on:remove="removeDay($event)"
      :id="time.id"
      :start="time.start"
      :end="time.end"
    />

    <v-btn
      color="primary"
      style="color: white;"
      v-on:click="addDay()"
      tile
    >
      + Add Time Slot
    </v-btn>

  </v-container>
</template>

<script>
import TwoTimes from '@/components/tools/time-calculator/TwoTimes.vue';

export default {
  name: 'TimeCalculator',

  components: {
    TwoTimes,
  },

  data: () => ({
    autoInc: -1,
    times: [],
    totalTime: 0,
    wage: 0,
    saved: null,
    savedColor: "",
  }),

  watch: {
    
  },

  methods: {
    addDay: function() {
      this.times.push({
        id: ++this.autoInc,
        start: null,
        end: null,
        diff: 0,
      })
      this.saveStateWarning();
    },
    removeDay: function(id) {
      for (let i = 0; i < this.times.length; i++) {
        if (this.times[i].id == id) {
          this.times.splice(i, 1);
          this.updateTotalTime();
          break;
        }
      }
      this.saveStateWarning();
    },
    updateValue: function(event) {
      for (let i = 0; i < this.times.length; i++) {
        if (this.times[i].id == event.id) {
          if (event.start)
            this.times[i].start = event.start;
          if (event.end)
            this.times[i].end = event.end;
          if (event.diff) {
            this.times[i].diff = event.diff;
            this.updateTotalTime();
          }
          this.saveStateWarning();
          break;
        }
      }
    },
    saveStateWarning() {
      if (this.saved)
        this.savedColor = 'warning';
    },
    updateTotalTime: function() {
      this.totalTime = 0;
      this.times.forEach(time => {
        this.totalTime += time.diff;
      });
    },
    saveTimes: function() {
      localStorage.setItem('time-calculator-wage', this.wage);
      localStorage.setItem('time-calculator-times', JSON.stringify(this.times));
      this.savedColor = 'green';
      this.saved = true;
    },
    loadTimes: function() {
      this.wage = localStorage.getItem('time-calculator-wage');
      this.times = [];
      this.times = JSON.parse(localStorage.getItem('time-calculator-times'));
      for (let i = 0; i < this.times.length; i++)
        if (this.times[i].id > this.autoInc)
          this.autoInc = this.times[i].id;
      this.savedColor = 'green';
      this.saved = true;
    }
  },

  created () {
    this.addDay();
    this.$emit('footer', true);
    this.$emit('toolbar', true);
  },

  mounted () {

  },
};
</script>

<style scoped>

</style>
