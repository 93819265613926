<template>
  <div class="px-5">
    <h1 class="my-5">Decision Maker</h1>

    <div class="d-flex justify-center mx-auto mb-5" style="max-width: 700px;">

      <div
        v-for="(category, i) in categories"
        :key="'category' + i"
        class="mx-3 cat-link"
        style="border-bottom: 1px solid gray;"
        @click="currentCategory = category"
      >
        <h4>{{ category }}</h4>
      </div>

    </div>

    <div class="d-flex flex-column flex-md-row justify-center align-center align-md-start">
      <div style="width: 100%;">
        <div
          v-if="currentCategory === 'Custom'"
        >
          <div
            class="d-flex justify-center align-center mx-auto"
            style="width: 100%; max-width: 400px;"
          >
            <v-select
              v-model="profile"
              :items="profiles"
              item-text="name"
              item-value="value"
              label="Profile Select"
              class="ma-2"
              style="width: 100%;"
            />
          </div>

          <div
            class="py-5 d-flex flex-wrap justify-center align-center mx-auto"
            style="width: 100%; max-width: 700px;"
          >
            <div
              v-for="(item, i) in items"
              :key="'item' + i"
              class="ma-4"
              style="max-width: 300px;"
            >
              <v-card class="pa-3" tile>
                <h3>{{ item }}</h3>
                <v-btn color="red" fab absolute top right x-small style="right: -16px;" @click="removeItem(i)"><v-icon color="white">mdi-close</v-icon></v-btn>
              </v-card>
            </div>
          </div>

          <v-form
            class="d-flex justify-center align-center mx-auto"
            style="max-width: 400px;"
            @submit="addItem"
          >
            <v-text-field
              v-model="newItem"
              placeholder="New Item"
              class="px-5 mx-auto"
              style="max-width: 300px;"
              :error-messages="newItemErrorMessage"
            />

            <div
              class="ma-2 d-flex justify-center"
            >
              <v-btn
                color="black"
                tile
                text
                @click="addItem"
              >
                Add Item
              </v-btn>
            </div>
          </v-form>
        </div>

        <div
          v-if="currentCategory === 'Restaurants'"
          class="mx-auto"
          style="width: 100%; max-width: 500px;"
        >

          <div class="mx-auto" style="width: 100%;">

            <div
              class="d-flex justify-center"
            >
              <v-btn class="ma-2" @click="placesRadius=1609.34"><v-icon>mdi-walk</v-icon></v-btn>
              <v-btn class="ma-2" @click="placesRadius=8046.72"><v-icon>mdi-car</v-icon></v-btn>
            </div>

            <v-slider
              v-model="placesRadius"
              label="Radius (mi)"
              :tick-labels="[1,2,3,4,5]"
              min="1609.34"
              max="8046.7"
              step="1609.34"
              ticks="always"
              tick-size="4"
            />

            <v-range-slider
              v-model="placesPrice"
              label="Price Range"
              :tick-labels="['0',1,2,3,4]"
              min="0"
              max="4"
              step="1"
              ticks="always"
              tick-size="4"
            />

            <v-combobox
              v-model="placesKeyword"
              :items="placesKeywordsItems"
              label="Cuisine (results may not be 100% accurate)"
            />

            <v-text-field
              id="pac-input"
              v-model="inputAddress"
              hide-details
              solo
              flat
              type="search"
              append-icon="mdi-magnify"
              style="background: white; border: 1px solid gray; outline: none; width: 100%; border-radius: 0px;"
              placeholder="Enter your address"
              autocomplete="off"
              @blur="addressAutocomplete=[]; autocompleteSelect=-1;"
              @focus="updateAutocomplete"
            ></v-text-field>

            <div
              style="width: 100%; position: relative; z-index:999;"
            >
              <div
                v-if="addressAutocomplete.length > 0"
                style="width: 100%; position: absolute;"
              >
                <div
                  v-for="(item, i) in addressAutocomplete"
                  :key="'autocomplete' + i"
                  @mouseover="hoverAutocomplete(i)"
                  @mousedown="getGeocode()"
                >
                  <div
                    v-if="i === autocompleteSelect"
                    style="background: #E6E6E6;"
                    class="autocomplete px-3 py-1"
                  >
                    <span >{{ item }}</span>
                  </div>

                  <div
                    v-else
                    style="background: #FFF;"
                    class="autocomplete px-3 py-1"
                  >
                    <span >{{ item }}</span>
                  </div>
                </div>
              </div>

              <div
                v-else-if="searching"
                style="width: 100%; position: absolute;"
              >
                <div
                  class="autocomplete px-3 py-0"
                >
                  <div
                    class="d-flex justify-center py-2 px-3"
                  >
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="restaurantMap"
            style="height: 0px; width: 100%;"
          />

          <div
            class="d-flex flex-wrap my-5"
          >
            <ul>
              <li
                v-for="(r, i) in items"
              >
                {{ r }}
              </li>
            </ul>
          </div>

          <div
            v-if="items.length > 0"
            class="my-5"
          >
            <h3>{{ items.length }} Results Found (Max 20)</h3>
          </div>

        </div>

        <div class="mt-5 mx-auto d-flex justify-space-around"
        style="max-width: 400px;">
          <v-btn
            color="primary"
            tile
            @click="openSaveProfile"
          >
            Save Profile
          </v-btn>

          <v-btn
            style="color: white;"
            color="green"
            tile
            @click="decide"
          >
            Decide!
          </v-btn>
        </div>

        <div
          v-if="decisionLoad || decision"
          class="mx-auto px-5 mt-10 d-flex justify-center align-center"
          style="max-width: 400px; width: 100%;"
        >
          <div class="pa-3" style="font-size: 32px; text-align: center; font-weight: bold;">
            
            <div v-if="decisionLoad"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            <div v-else-if="decision">{{ decision }}</div>
            
            </div>
        </div>
      </div>

      <div v-if="false" id="ad_container" class="ml-0 ml-md-5 mt-5 mt-md-0" style="background: #FFFFFF50">
        <div v-if="adsenseContent==''">Ad</div>
        <div v-else id="adsgoeshere" v-html="adsenseContent"></div>
      </div>
    </div>

    <v-dialog
      v-model="profileNameDialog"
    >
      <v-form
        class="d-flex justify-center align-center mx-auto"
        style="max-width: 400px; background: white;"
        @submit="saveProfile"
      >
        <v-text-field
          v-model="newProfile"
          placeholder="Profile Name"
          class="px-5 mx-auto"
          style="max-width: 300px;"
          autofocus
          :error-messages="newProfileErrorMessage"
        />

        <div
          class="ma-2 d-flex justify-center"
        >
          <v-btn
            color="green"
            style="color: white;"
            tile
            @click="saveProfile"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'DecisionMaker',

  components: {

  },

  props: {

  },

  data: () => ({
    apiKey: 'AIzaSyAzLaucopneMxRZ7wykJQuqfyatSvIfNvM',
    sessionToken: '',
    profiles: [],
    profile: null,
    items: [],
    newItem: null,
    newItemErrorMessage: null,
    profileNameDialog: false,
    newProfile: null,
    newProfileErrorMessage: null,
    decision: null,
    decisionLoad: false,
    adsenseContent: '',
    placesRadius: 1609.34,
    placesLocation: null,
    placesPrice: [0, 4],
    placesOpennow: true,
    places: null,
    categories: [
      'Custom',
      'Restaurants',
    ],
    currentCategory: 'Custom',
    geocoder: null,
    addressAutocomplete: [],
    inputAddress: null,
    autocompleteSelect: -1,
    searching: false,
    autocompleteService: null,
    placesService: null,
    placesMap: null,
    placesKeyword: 'All',
    placesKeywordsItems: [
      'All',
      'American',
      'Barbeque',
      'Chinese',
      'Indian',
      'Japanese',
      'Korean',
      'Mexican',
      'Peurto Rican',
      'Sandwich',
      'Soup',
      'Thai',
      'Vietnamese',
    ],
    placesRadiusTimeout: null,
    geocoderService: null,
  }),

  watch: {
    newItem(item) {
      this.newItemErrorMessage = null;
    },

    profile(profile) {
      this.items = this.profiles.find((p) => {
        return p.name === profile
      }).items;
    },

    currentCategory(cat) {
      this.items = [];
      this.decision = '';
    },

    inputAddress() {
      if (!this.geocoding)
        this.updateAutocomplete();
    },

    placesKeyword(val) {
      if (this.inputAddress != null && this.inputAddress.length > 0) {
        this.items = [];
        this.decisionLoad = true;
        this.getPlaces();
      }
    },

    placesRadius() {
      clearTimeout(this.placesTimeout);
      if (this.inputAddress != null && this.inputAddress.length > 0) {
        this.decisionLoad = true;
        this.placesTimeout = setTimeout(() => {
          this.items = [];
          this.getPlaces();
        }, 1000);
      }
    },

    placesPrice() {
      clearTimeout(this.placesTimeout);
      if (this.inputAddress != null && this.inputAddress.length > 0) {
        this.decisionLoad = true;
        this.placesTimeout = setTimeout(() => {
          this.items = [];
          this.getPlaces();
        }, 1000);
      }
    }
  },
  
  computed: {

  },

  methods: {
    addItem(e) {
      e.preventDefault();
      if (this.newItem == null) this.newItem = "";
      this.newItem = this.newItem.trim();
      if (this.newItem.length > 0) {
        this.items.push(this.newItem);
        this.newItemErrorMessage = null;
        this.newItem = "";
      } else {
        this.newItemErrorMessage = "This field is required."
      }
    },

    removeItem(i) {
      this.items.splice(i, 1);
    },

    decide() {
      this.decisionLoad = true;
      setTimeout(() => {
        this.decision = this.items[Math.floor(Math.random() * this.items.length)]
        this.decisionLoad = false;
      }, 1500);
    },

    openSaveProfile() {
      if (this.profile !== null) this.newProfile = this.profile;
      this.profileNameDialog = true;
      this
    },

    saveProfile(e) {
      e.preventDefault();
      if (this.newProfile == null) this.newProfile = "";
      this.newProfile = this.newProfile.trim();
      if (this.newProfile.length > 0) {
        
        const exists = this.profiles.find((p) => {
          return p.name === this.newProfile
        })
        if (exists != null) {
          this.profiles[this.profiles.indexOf(exists)].items = this.items;
        } else {
          this.profiles.push({
            name: this.newProfile,
            value: this.newProfile,
            items: this.items,
          })
        }
        localStorage.setItem('decisionmaker-profiles', JSON.stringify(this.profiles));

        this.profile = this.newProfile;
        this.newProfileErrorMessage = null;
        this.newProfile = "";
        this.profileNameDialog = false;
      } else {
        this.newProfileErrorMessage = "This field is required."
      }
    },

    load() {
      const v = JSON.parse(localStorage.getItem('decisionmaker-profiles'));
      if (v === null) {
        this.profiles = [];
      } else {
        this.profiles = v;
      }
    },

    getGeocode() {
      this.geocoding = true;
      this.decisionLoad = true;
      const address = this.addressAutocomplete[this.autocompleteSelect];
      this.inputAddress = address;
      this.addressAutocomplete = [];
      this.geocoderService.geocode({
        address: this.inputAddress
      }, (results, status) => {
        if (status === 'OK') {
          const latlng = results[0].geometry.location;
          this.placesLocation = new google.maps.LatLng(latlng.lat(), latlng.lng());
          this.placesMap = new google.maps.Map(
            document.getElementById('restaurantMap'),
            {
              center: latlng,
              zoom: 15,
            }
          )
          this.placesService = new google.maps.places.PlacesService(this.placesMap);
          this.items = [];
          this.geocoding = false;
          this.getPlaces();
        } else {
          console.error(status);
        }
      })
      document.getElementById('pac-input').blur();
    },

    nearbySearch(request) {
      this.placesService.nearbySearch(request,
      (data, status) => {
        if (status === 'OK') {
          if (data.length === 0) {
            this.decision = 'No open restaurants found';
            this.decisionLoad = false;
          } else {
            data.forEach((e) => {
              if (!this.items.includes(e.name))
                this.items.push(e.name);
            });
            this.items = this.items.sort();
            if (data.next_page_token != null) {
              setTimeout(() => {
                this.getPlacesPage(data.next_page_token);
              }, 2000);
            } else {
              this.decide();
            }
          }
        } else if (status === 'ZERO_RESULTS') {
          this.decision = 'No open restaurants found';
          this.decisionLoad = false;
        } else {
          console.error(status);
        }
      })
    },

    getPlaces() {
      let keyword = '';
      if (this.placesKeyword !== 'All') {
        keyword = this.placesKeyword;
      } else {
        keyword = '';
      }
      this.nearbySearch({
        location: this.placesLocation,
        radius: this.placesRadius,
        type: 'restaurant',
        minPriceLevel: this.placesPrice[0],
        maxPriceLevel: this.placesPrice[1],
        openNow: true,
        keyword: keyword,
      });
    },

    getPlacesPage(pagetoken) {
      this.nearbySearch({
        pageToken: pagetoken
      });
    },

    updateAutocomplete() {
      if (this.inputAddress !== null && this.inputAddress.length > 0) {
        this.searching = true;
        this.autocompleteService.getPlacePredictions({
          input: this.inputAddress,
          sessionToken: this.sessionToken,
        }, (data, status) => {
          if (status === 'OK') {
            this.searching = false;
            this.addressAutocomplete = [];
            this.autocompleteSelect = 0;
            data.forEach((e) => {
              this.addressAutocomplete.push(e.description);
            });
          } else {
            console.error(status);
          }
        })
      } else {
        this.addressAutocomplete = [];
      }
    },

    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    selectAutocomplete(e) {
      if (e.key === 'ArrowUp') {
        if (this.autocompleteSelect - 1 < 0) {
          this.autocompleteSelect = this.addressAutocomplete.length - 1;
        } else {
          this.autocompleteSelect--;
        }
      } else if (e.key === 'ArrowDown') {
        if (this.autocompleteSelect + 1 >= this.addressAutocomplete.length) {
          this.autocompleteSelect = 0;
        } else {
          this.autocompleteSelect++;
        }
      } else if (e.key === 'Enter') {
        if (this.autocompleteSelect >= 0 && this.autocompleteSelect < this.addressAutocomplete.length) {
          this.getGeocode();
        }
      }
    },

    hoverAutocomplete(idx) {
      this.autocompleteSelect = idx;
    },
  },

  destroyed () {
    window.removeEventListener('keydown', this.selectAutocomplete);
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
  },

  mounted () {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoderService = new google.maps.Geocoder();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.load();
    window.addEventListener('keydown', this.selectAutocomplete);
    // this.adsenseContent = document.getElementById('adsenseaddesktopside').innerHTML;
  }
}
</script>

<style scoped>
  .cat-link {
    cursor: pointer;
    opacity: 1;
  }
  .cat-link:hover {
    opacity: 0.5;
  }

  .autocomplete {
    background: #FFF;
    border: 1px solid #888;
    border-top: none;
    cursor: pointer;
    width: 100%;
  }
</style>
