import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const colors = {
  blue: '#4573A6',
  green: '#46C22C',
  yellow: '#E9D935',
  orange: '#E97C35',
  red: '#E94235',
}

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue,
        secondary: colors.orange,
        accent: colors.yellow,
        success: colors.green,
        warning: colors.orange,
        danger: colors.red,
      },
      dark: {
        primary: colors.blue,
        secondary: colors.orange,
        accent: colors.yellow,
        success: colors.green,
        warning: colors.orange,
        danger: colors.red,
      }
    }
  }
});
