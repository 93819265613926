<template>
  <div>
    <div class="d-flex justify-space-around mb-5">
      <v-btn class="mx-2" @click="$emit('restart', 'sorted')">Sorted</v-btn>
      <v-btn class="mx-2" @click="$emit('restart', 'unsorted')">Unsorted</v-btn>
      <v-btn class="mx-2" @click="$emit('restart', 'random')">Random</v-btn>
    </div>
    <div class="text-center">n = {{ data.n }}, steps = {{ steps }}</div>
    <canvas height="2000" width="3000" style="position: relative; background: #e6e6e6; height: 100%; width: 100%;"/>
    <div class="d-flex justify-space-around align-center mx-3 flex-column flex-sm-row">
      <div class="d-flex justify-center align-center mb-3 mr-0 mb-sm-0 mr-sm-3">
        
        <v-btn small color="white" @click="$emit('speed', speed-25)">
          <v-icon>
            mdi-minus-thick
          </v-icon>
        </v-btn>

        <div class="px-3 mx-auto text-center" style="width: 100px;">Delay<br>({{ speed }} ms)</div>
        
        <v-btn small color="white" @click="$emit('speed', speed+25)">
          <v-icon>
            mdi-plus-thick
          </v-icon>
        </v-btn>

      </div>

      <v-btn color="red" style="color: white; font-weight: bold;" @click="$emit('stop')">Stop</v-btn>
    </div>
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'BarGraph',

  components: {

  },

  props: {
    elems: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    red: {
      type: Number,
      required: false,
      default: -1,
    },
    green: {
      type: Number,
      required: false,
      default: -1,
    },
    blue: {
      type: Number,
      required: false,
      default: -1,
    },
    lo: {
      type: Number,
      required: false,
      default: -1,
    },
    hi: {
      type: Number,
      required: false,
      default: -1,
    },
    sorted: {
      type: Boolean,
      required: false,
      default: false,
    },
    steps: {
      type: Number,
      required: false,
      default: -1,
    },
    speed: {
      type: Number,
      required: false,
      default: -1,
    }
  },

  data: () => ({
    canvas: null,
    ctx: null,
  }),

  watch: {

  },
  computed: {

  },

  methods: {

    draw() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const barW = this.canvas.width / (this.data.n);
      const scalar = this.canvas.height / this.data.max;
      for (let i = 0; i < this.elems.length; i++) {
        this.ctx.beginPath();
        this.ctx.rect(i * barW, this.canvas.height - (this.elems[i] * scalar), barW, this.elems[i] * scalar);
        this.ctx.fillStyle = '#333';

        if (this.sorted) {
          this.ctx.fillStyle = '#333';
        } else if (i == this.red) {
          this.ctx.fillStyle = '#FF000080';
        } else if (i == this.green) {
          this.ctx.fillStyle = '#00FF0080';
        } else if (i == this.blue) {
          this.ctx.fillStyle = '#0000FF80';
        } else if (i >= this.lo && i <= this.hi) {
          this.ctx.fillStyle = '#808080';
        }

        this.ctx.fill();
      }
    }
  },

  mounted () {
    this.canvas = document.getElementsByTagName('canvas')[0];
    this.ctx = this.canvas.getContext('2d');
    setInterval(() => {
      this.draw();
    }, 1);
  }
}
</script>

<style scoped>

</style>
