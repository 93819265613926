<template>
  <div
    id="container"
    class="d-flex align-center justify-center px-5"
    style="width: 100%; height: 100%;"
  >
    <canvas id="bc" :height="documentHeight" :width="documentWidth" v-on:click="burst" style="opacity: .25;"></canvas>
    <v-btn
      fab
      color="primary"
      class="ma-3"
      style="position: absolute; left: 0; top: 0;"
      v-on:click="$router.back()"
    >
      <v-icon x-large>
        mdi-chevron-left
      </v-icon>
    </v-btn>

    <v-card
      class="d-flex flex-column align-center pa-5"
      style="max-width: 412px;"
      :flat="flat"
    >
      <router-view />
    </v-card>
  </div>
</template>

<script>
const g = 0.000001;
export default {
  name: 'FormOnly',

  data: () => ({
    documentWidth: 0,
    documentHeight: 0,
    flat: false,
    loading: false,
    errorMessage: null,
    rects: [],
    colors: ['#E94235', '#E97C35', '#E9D935', '#46C22C', '#4573A6'],
    redrawTimeout: null,
    updateInterval: null,
    canvas: null,
    ctx: null,
  }),

  methods: {
    onresize() {
      if (window.innerWidth < 460)
        this.flat = true;
      else
        this.flat = false;
      this.documentWidth = document.body.clientWidth;
      this.documentHeight = document.body.clientHeight;
      this.redrawBubbles();
    },

    update() {
      let newR = []
      this.rects.forEach(r1 => {
        let Gx = 0;
        let Gy = 0;
        this.rects.forEach(r2 => {
          if (r1 != r2) {
            let m = r1.s * r2.s
            let rx2 = Math.abs(r2.x - r1.x);
            let ry2 = Math.abs(r2.y - r1.y);
            let r = Math.sqrt(Math.pow(r2.x - r1.x, 2)+Math.pow(r2.y - r1.y, 2));
            let Fg = g * (m / r) * 10;
            
            let Gx_tmp = Fg * (rx2 / (rx2 + ry2));
            let Gy_tmp = Fg * (ry2 / (rx2 + ry2));
            
            if (r1.x > r2.x)
              Gx_tmp *= -1;
            if (r1.y > r2.y)
              Gy_tmp *= -1;

            Gx += Gx_tmp;
            Gy += Gy_tmp;
          }
        });
        newR.push({
          Gx: Gx,
          Gy: Gy,
        });
      });
      
      this.rects.forEach((r, i) => {
        r.Gx = newR[i].Gx;
        r.Gy = newR[i].Gy;

        r.ax += r.Gx / r.s;
        r.ay += r.Gy / r.s;

        r.ax = this.limit(r.ax, -.5, .5);
        r.ay = this.limit(r.ay, -.5, .5);

        r.vx += r.ax;
        r.vy += r.ay;
        // r.vx = this.limit(r.vx, -.7, .7);
        // r.vy = this.limit(r.vy, -.7, .7);

        r.x += r.vx;
        r.y += r.vy;

        if (r.x + r.s <= 0 && r.vx < 0) {
          r.x = this.documentWidth + r.s;
          r.vx = 0;
        }
        if (r.x - r.s >= this.documentWidth && r.vx > 0) {
          r.x = 0 - r.s;
          r.vx = 0;
        }
        if (r.y + r.s < 0 && r.vy < 0) {
          r.y = this.documentHeight + r.s;
          r.vy = 0;
        }
        if (r.y - r.s >= this.documentHeight && r.vy > 0) {
          r.y = 0 - r.s;
          r.vy = 0;
        }
      });
      this.draw();
    },

    intersect(r1, r2) {
      return (r1.x > r2.x + r2.w || r1.x + r1.w < r2.x || r1.y > r2.y + r2.h || r1.y + r1.h < r2.y);
    },

    limit(val, lo, hi) {
      if (val < lo)
        return lo;
      if (val > hi)
        return hi;
      return val;
    },

    burst(e) {
      this.rects.forEach(r2 => {
        let m = 1 * r2.s
        let rx2 = Math.abs(r2.x - e.clientX);
        let ry2 = Math.abs(r2.y - e.clientY);
        let r = Math.sqrt(Math.pow(r2.x-e.clientX, 2)+Math.pow(r2.y-e.clientY, 2));
        let Fg = g*(m/r) * 100000000;
        
        let Gx_tmp = Fg * (rx2 / (rx2 + ry2));
        let Gy_tmp = Fg * (ry2 / (rx2 + ry2));
        
        if (e.clientX > r2.x)
          Gx_tmp *= -1;
        if (e.clientY > r2.y)
          Gy_tmp *= -1;
        
        r2.vx += Gx_tmp;
        r2.vy += Gy_tmp;
      });
    },

    draw() {
      this.ctx.clearRect(-this.documentWidth,-this.documentHeight,this.documentWidth*2,this.documentHeight*2)
      this.rects.forEach(rect => {
        this.ctx.beginPath();
        this.ctx.lineWidth = rect.lineWidth;
        this.ctx.strokeStyle = rect.color;
        this.ctx.arc(rect.x, rect.y, rect.s, 0, Math.PI * 2);
        // this.ctx.fillText('Gx: ' + rect.Gx.toFixed(8), rect.x, rect.y-55); 
        // this.ctx.fillText('Gy: ' + rect.Gy.toFixed(8), rect.x, rect.y-45);
        // this.ctx.fillText('ax: ' + rect.ax.toFixed(8), rect.x, rect.y-35); 
        // this.ctx.fillText('ay: ' + rect.ay.toFixed(8), rect.x, rect.y-25);
        // this.ctx.fillText('vx: ' + rect.vx.toFixed(8), rect.x, rect.y-15); 
        // this.ctx.fillText('vy: ' + rect.vy.toFixed(8), rect.x, rect.y-5);
        this.ctx.stroke();
      });
    },

    redrawBubbles() {
      clearTimeout(this.redrawTimeout);
      this.redrawTimeout = setTimeout(this.createBubbles, 1)
    },

    createBubbles() {
      clearInterval(this.updateInterval); 
      this.rects = [];
      let bubbleCount = Math.round(this.documentWidth * this.documentHeight / 10000);
      if (bubbleCount > 100)
        bubbleCount = 100;
      for (let i = 0; i < bubbleCount; i++) {
        this.rects.push({
          x: Math.random() * this.documentWidth,
          y: Math.random() * this.documentHeight,
          s: Math.random() * (this.documentWidth * this.documentHeight / 20000) + 15,
          vx: 0,
          vy: 0,
          ax: 0,
          ay: 0,
          Gx: 0,
          Gy: 0,
          lineWidth: Math.ceil(Math.random()*10),
          color: this.colors[Math.round(Math.random()*5)]
        })
      }
      this.updateInterval = setInterval(this.update, 1000 / 60);
    },
  },

  created () {
    this.$emit('footer', false);
    this.$emit('toolbar', false);
  },

  beforeRouteLeave (to, from, next) {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
    next();
  },

  destroyed () {
    window.removeEventListener('resize', this.onresize);
  },

  mounted () {
    window.addEventListener('resize', this.onresize);
    this.canvas = document.getElementById('bc');
    this.ctx = this.canvas.getContext('2d');
    this.onresize();
  },
};
</script>

<style>
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
</style>