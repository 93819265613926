import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Home from '../views/Home.vue'
import Contact from '@/views/Contact.vue'
import NeedAWebsite from '@/views/NeedAWebsite.vue'
import Portfolio from '@/views/Portfolio.vue'

import CardList from '@/views/CardList.vue'
import Resource from '@/views/Resource.vue'

/* Tools */
import TimeCalculator from '@/views/tools/TimeCalculator.vue'
import DecisionMaker from '@/views/tools/DecisionMaker.vue'
import SteamParty from '@/views/tools/SteamParty.vue'

import FormOnly from '@/templates/FormOnly.vue'
import SigninForm from '@/components/forms/SigninForm.vue'
import SignupForm from '@/components/forms/SignupForm.vue'
import RecoverForm from '@/components/forms/RecoverForm.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: FormOnly,
    meta: {
      title: '3DOT | Auth'
    },
    children: [
      {
        path: 'signin',
        component: SigninForm,
        props: {
          title: 'Sign In',
          loadingTitle: 'Signing you in...',
        },
        meta: {
          title: '3DOT | Sign In'
        }
      },
      {
        path: 'signup',
        component: SignupForm,
        props: {
          title: 'Sign Up',
          loadingTitle: 'Signing you up...',
        },
        meta: {
          title: '3DOT | Sign Up'
        }
      },
      {
        path: 'recover',
        component: RecoverForm,
        props: {
          title: 'Recovery',
          loadingTitle: 'Sending you an email...',
        },
        meta: {
          title: '3DOT | Recover'
        }
      },
    ]
  },

  {
    path: '/',
    name: 'Home',
    meta: {
      title: '3DOT | Home'
    },
    component: Home
  },

  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: '3DOT | Contact'
    },
    component: Contact
  },

  {
    path: '/need-a-website',
    name: 'Need A Website',
    meta: {
      title: '3DOT | Need A Website'
    },
    component: NeedAWebsite
  },

  {
    path: '/portfolio',
    name: 'Portfolio',
    meta: {
      title: '3DOT | Portfolio'
    },
    component: Portfolio
  },

  {
    path: '/tools',
    name: 'Tools',
    meta: {
      title: '3DOT | Tools',
    },
    component: CardList,
    props: {
      title: 'Tools',
      cards: require('@/assets/structures/tools.js')
    },
  },
    {
      path: '/tools/time-calculator',
      name: 'Time Calculator',
      meta: {
        title: '3DOT | Time Calculator'
      },
      component: TimeCalculator
    },
    {
      path: '/tools/decision-maker',
      name: 'Decision Maker',
      meta: {
        title: '3DOT | Decision Maker'
      },
      component: DecisionMaker
    },
    {
      path: '/tools/steam-party',
      name: 'Steam Party',
      meta: {
        title: '3DOT | Steam Party'
      },
      component: SteamParty
    },

  {
    path: '/resources',
    name: 'Resources',
    meta: {
      title: '3DOT | Resources',
    },
    component: CardList,
    props: {
      title: 'Resources',
      cards: require('@/assets/structures/resources.js')
    }
  },
    {
      path: '/resources/sorting',
      name: 'Sorting',
      meta: {
        title: '3DOT | Sorting',
      },
      component: CardList,
      props: {
        title: 'Sorting Algorithms',
        cards: require('@/assets/structures/sorting.js')
      }
    },
      {
        path: '/resources/sorting/quicksort',
        name: 'Quicksort',
        meta: {
          title: '3DOT | Quicksort',
        },
        component: Resource,
        props: {
          title: 'Quicksort',
          items: require('@/assets/structures/quicksort.js')
        }
      },
      {
        path: '/resources/sorting/mergesort',
        name: 'Mergesort',
        meta: {
          title: '3DOT | Mergesort',
        },
        component: Resource,
        props: {
          title: 'Mergesort',
          items: require('@/assets/structures/mergesort.js')
        }
      },
      {
        path: '/resources/sorting/insertion-sort',
        name: 'Insertion Sort',
        meta: {
          title: '3DOT | Insertion Sort',
        },
        component: Resource,
        props: {
          title: 'Insertion Sort',
          items: require('@/assets/structures/insertion-sort.js')
        }
      },
      {
        path: '/resources/sorting/heapsort',
        name: 'Heapsort',
        meta: {
          title: '3DOT | Heapsort',
        },
        component: Resource,
        props: {
          title: 'Heapsort',
          items: require('@/assets/structures/heapsort.js')
        }
      },
      {
        path: '/resources/sorting/bubble-sort',
        name: 'Bubble Sort',
        meta: {
          title: '3DOT | Bubble Sort',
        },
        component: Resource,
        props: {
          title: 'Bubble Sort',
          items: require('@/assets/structures/bubble-sort.js')
        }
      },
      {
        path: '/resources/sorting/selection-sort',
        name: 'Selection Sort',
        meta: {
          title: '3DOT | Selection Sort',
        },
        component: Resource,
        props: {
          title: 'Selection Sort',
          items: require('@/assets/structures/selection-sort.js')
        }
      },
      {
        path: '/resources/sorting/stooge-sort',
        name: 'Stooge Sort',
        meta: {
          title: '3DOT | Stooge Sort',
        },
        component: Resource,
        props: {
          title: 'Stooge Sort',
          items: require('@/assets/structures/stooge-sort.js')
        }
      },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next();
})

export default router
