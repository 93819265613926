import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { enc } from 'crypto-js'
const jwt = require('jsonwebtoken');

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // if (to.meta.requiresAuth) {
  //   if (localStorage.getItem('authToken') && localStorage.getItem('secret')) {
  //     fetch('https://qsm3cnlf30.execute-api.us-west-2.amazonaws.com/dev/v1/auth', {
  //       method: 'POST',
  //       mode: 'no-cors',
  //       cache: 'no-cache',
  //       credentials: 'same-origin',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       redirect: 'follow',
  //       referrer: 'no-referrer',
  //       body: JSON.stringify({
  //         token: jwt.sign(
  //           localStorage.getItem('authToken'),
  //           localStorage.getItem('secret')
  //         )
  //       })
  //     }).then((res) => {
  //       if (!res.ok)
  //         next('/signin');
  //       res.json().then((data) => {
  //         console.log('data', data);
  //         if (jwt.verify(data.authorized, localStorage.getItem('secret')) === 'true')
  //           next('/signin')
  //         next();
  //       })
  //     })
  //   }
  //   next('/signin')
  // }
  // else
    next();
});

const DEFAULT_TITLE = '3DOT'
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  });
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')