module.exports = 
[
  {
    title: 'Time Calculator',
    description: 'A tool for adding start and end times together with the option to multiply by a dollar amount.',
    link: '/tools/time-calculator'
  },
  {
    title: 'Decision Maker',
    description: 'Tired of being indecisive? Use this tool to make the difficult decision for you.',
    link: '/tools/decision-maker'
  },
  {
    title: 'Steam Party',
    description: 'Add in you and your friend\'s Steam IDs and get a list of all the games you share.',
    link: '/tools/steam-party'
  }
]