<template>
  <v-container>
    <h1 class="my-5">{{ title }}</h1>
    <div
      class="d-flex flex-wrap align-center justify-center"
    >
      <v-card
        v-for="card in cards"
        v-bind:key="card.title"
        :to="card.link"
        :style="'width: 300px;'"
        link
        tile
        class="ma-3"
      >
        <v-card-title v-if="card.title">
          {{ card.title }}
        </v-card-title>
        <v-card-text v-if="card.description">
          {{ card.description }}
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'CardList',

  components: {

  },

  props: {
    title: {
      type: String,
      default: 'Default Title'
    },
    cards: {
      type: Array,
      required: true,
    }
  },

  data: () => ({

  }),

  watch: {

  },

  methods: {
    getRandomBG() {
      const offset = 100;
      let rgb = []
      for (let i = 0; i < 3; i++)
        rgb.push(Math.random() * offset + (256 - offset));
      rgb[0] -= 0;
      rgb[1] += 60;
      rgb[2] += 0;
      return 'rgb(' + rgb.join(',') + ')';
    }
  },

  created () {
    this.$emit('footer', true);
    this.$emit('toolbar', true);
  },

  mounted () {

  },
};
</script>

<style scoped>
  a, a:hover, .v-card--link:hover, .v-card:hover {
    text-decoration: none !important;
  }
</style>
