<template>
  <svg :width="size" :height="size" viewBox="0 0 392 392" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="goto(to)">
    <circle cx="75" cy="75" r="75" fill="#E94235"/>
    <circle cx="317" cy="75" r="75" fill="#E9D935"/>
    <circle cx="317" cy="317" r="75" fill="#2C5F98"/>
  </svg>
</template>

<script>
export default {
  name: "Logo",

  props: {
    size: {
      type: String,
      required: false,
      default: '40',
    },
    to: {
      type: String,
      required: false,
      default: null,
    }
  },

  methods: {
    goto(path) {
      if (this.$route.path != path)
        this.$router.push(path);
    }
  }
}
</script>